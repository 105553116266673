import React, { memo, useCallback, useMemo } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCart } from "../../../../hooks/useCart"
import { useCheckout } from "../../../../hooks/useCheckout"

export const withProductDetailAddToCart = Component =>
  memo(
    ({
      name = "ProductDetailAddToCart",
      colour,
      component = null,
      handleChange,
      loading,
      quantity,
      product,
      template,
      bar,
      notify,
      prototype,
    }: any) => {
      const { activeVariant: selectedVariant, setActiveProductNotify } = useApp()
      const { addToCart, loading: adding } = useCart()
      const { clientId } = useCheckout()

      const handleAdd = useCallback(() => addToCart(selectedVariant?.id, quantity), [addToCart, quantity, selectedVariant])

      const handleNotify = useCallback(() => setActiveProductNotify(product?.handle), [product, setActiveProductNotify])

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            adding={adding}
            colour={colour}
            component={component}
            handleAdd={handleAdd}
            handleChange={handleChange}
            handleNotify={handleNotify}
            loading={loading}
            locales={template}
            product={product}
            quantity={quantity}
            selectedVariant={selectedVariant}
            clientId={clientId}
            bar={bar}
            notify={notify}
            prototype={prototype}
          />
        ),
        [
          adding,
          colour,
          component,
          handleAdd,
          handleChange,
          handleNotify,
          loading,
          template,
          product,
          quantity,
          selectedVariant,
          clientId,
          bar,
          notify,
          prototype,
        ]
      )
    }
  )
