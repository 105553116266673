import React, { memo, useCallback, useMemo, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useBreadcrumb } from "../../../hooks/useBreadcrumb"
import { useCore } from "../../../hooks/useCore"
import { useDom } from "../../../hooks/useDom"
import { useRoutes } from "../../../hooks/useRoutes"
import { useShopifyPrice } from "../../../hooks/useShopify"

export const withProductDetail = Component =>
  memo(({ name = "ProductDetail", add, bar, grouped, ingredients, loading, product, setBar, template }: any) => {
    const { activeVariant } = useApp()
    const { breadcrumb } = useBreadcrumb()
    const {
      helpers: { isBrowser },
    } = useCore()
    const { dom } = useDom()
    const { linkResolver, parentCollections } = useRoutes()
    const { formattedPrice, formattedCompareAtPrice, formattedInstallmentPrice, onSale } = useShopifyPrice(activeVariant, product.giftcard)
    const [quantity, setQuantity] = useState(1)

    const breadcrumbs = useMemo(
      () => [
        ...(product?.metadata?.breadcrumbs?.map((breadcrumb: any) => linkResolver(breadcrumb)) || []),
        ...parentCollections(product?.content?.primaryCollection, true),
      ],
      [linkResolver, parentCollections, product]
    )
    const hasIngredients = product?.content?.recipe?.length > 0 || product?.content?.ingredients?.length > 0

    const handleQuantity = useCallback((quantity: number) => setQuantity(quantity), [])
    const handleScroll = useCallback(
      () =>
        isBrowser && ingredients?.current
          ? window.scrollTo({ top: ingredients.current?.offsetTop + (dom?.isLarge ? -40 : dom?.width), behavior: "smooth" })
          : null,
      [dom, ingredients, isBrowser]
    )
    const handleVisibility = useCallback(
      (visible: boolean) => {
        if (bar === visible) {
          setBar(!visible)
        }
      },
      [bar, setBar]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          add={add}
          breadcrumb={breadcrumb}
          breadcrumbs={breadcrumbs}
          formattedPrice={formattedPrice}
          formattedCompareAtPrice={formattedCompareAtPrice}
          formattedInstallmentPrice={formattedInstallmentPrice}
          grouped={grouped}
          handleQuantity={handleQuantity}
          handleScroll={handleScroll}
          handleVisibility={handleVisibility}
          hasIngredients={hasIngredients}
          loading={loading}
          onSale={onSale}
          product={product}
          quantity={quantity}
          selectedVariant={activeVariant}
          template={template}
          bar={bar}
        />
      ),
      [
        activeVariant,
        add,
        breadcrumb,
        breadcrumbs,
        formattedPrice,
        formattedCompareAtPrice,
        formattedInstallmentPrice,
        grouped,
        handleQuantity,
        handleScroll,
        handleVisibility,
        hasIngredients,
        loading,
        onSale,
        product,
        quantity,
        template,
      ]
    )
  })
