import React from "react"

import { withProductContentReviewsForm } from "./withProductContentReviewsForm"
import { FormInput } from "../../../../Form/Input/FormInput"
import { FormTextarea } from "../../../../Form/Textarea/FormTextarea"
import { Icon } from "../../../../Icon/Icon"
import { H4 } from "../../../../Styled/Text"
import { Review, Inner, Popup, Form, Close, Button, Rating, Label, Radio, Star } from "./styledProductContentReviewsForm"

export const ProductContentReviewsForm = withProductContentReviewsForm(
  ({ active, bounds, data, errors, field, handleActive, handleChange, handleSubmit, loading, locales, success }): JSX.Element => (
    <Review active={active} top={bounds?.height}>
      <Inner>
        <Popup active={active}>
          {success ? (
            <H4 align={`center`} withSpacing={`xs`}>
              {locales?.additionalReviewsSuccess}
            </H4>
          ) : (
            <Form onSubmit={handleSubmit}>
              <H4 align={`center`} withSpacing={`xs`}>
                {locales?.additionalReviewsWrite}
              </H4>
              <FormInput
                type={`author`}
                name={`author`}
                disabled={loading}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalReviewsName}
                required
                reference={field}
                value={data?.author}
                withSpacing
              />
              <FormInput
                type={`email`}
                name={`email`}
                disabled={loading}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalReviewsEmail}
                required
                value={data?.email}
                withSpacing
              />
              <FormInput
                type={`location`}
                name={`location`}
                disabled={loading}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalReviewsLocation}
                value={data?.location}
                withSpacing
              />
              <Label>{locales?.additionalReviewsRating}</Label>
              <Rating justify={`start`} withSpacing>
                {Array.from(Array(5).keys()).map((_, i) => (
                  <Star key={i?.toString()} active={i < data?.reviewRating}>
                    <Radio type={`radio`} checked={data?.reviewRating === i + 1} name={`reviewRating`} onChange={handleChange} value={i + 1} />
                    <Icon name={`star`} width={24} />
                  </Star>
                ))}
              </Rating>
              <FormInput
                type={`reviewTitle`}
                name={`reviewTitle`}
                disabled={loading}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalReviewsTitle}
                required
                value={data?.reviewTitle}
                withSpacing
              />
              <FormTextarea
                type={`reviewMessage`}
                name={`reviewMessage`}
                disabled={loading}
                errors={errors}
                full
                inverted
                onChange={handleChange}
                placeholder={locales?.additionalReviewsContent}
                required
                value={data?.reviewMessage}
                withSpacing
              />
              <Button type={`submit`} disabled={loading} colour={`purple`} size={`secondary`} title={locales?.additionalReviewsSubmit}>
                {locales?.additionalReviewsSubmit}
              </Button>
            </Form>
          )}
          <Close colour={`white`} onClick={handleActive} title={locales?.additionalClose}>
            <Icon name={`close`} />
          </Close>
        </Popup>
      </Inner>
    </Review>
  )
)
