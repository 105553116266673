import React, { memo, useMemo } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useShopify } from "../../../../hooks/useShopify"
import { useTemplate } from "../../../../hooks/useTemplate"

export const withProductContentRecommendations = Component =>
  memo(({ name = "ProductContentRecommendations", id, recommendations: rawRecommendations, related: rawRelated, template }: any) => {
    const { headerBounds } = useApp()
    const { productNormaliser, useProducts } = useShopify()
    const { global } = useTemplate()

    const locales = useMemo(
      () => ({
        ...global,
        ...template,
      }),
      [global, template]
    )

    const recommendations = useProducts({ firstImages: 5, firstVariants: 1, handles: rawRecommendations?.map(({ handle }) => handle) || [] })
    const related = useProducts({
      firstImages: 5,
      firstVariants: 1,
      handles: rawRelated?.map(item => item?.shopify?.handle).filter(handle => handle) || [],
    })
    const items =
      related?.length > 0
        ? related?.map((product: any) => productNormaliser(product))
        : recommendations?.map((product: any) => productNormaliser(product))
    const autoplay =
      template?.recommendationsAutoplay && template?.recommendationsAutoplaySeconds ? template.recommendationsAutoplaySeconds * 1000 : null
    const colour = template?.recommendationsArrows?.toLowerCase()?.replace(`light`, `white`)

    Component.displayName = name
    return useMemo(
      () =>
        items?.length > 0 ? <Component autoplay={autoplay} bounds={headerBounds} colour={colour} id={id} items={items} locales={locales} /> : null,
      [autoplay, colour, headerBounds, id, items, locales]
    )
  })
