import React from "react"

import { withProductContent } from "./withProductContent"
import { ProductContentAccordion } from "./Accordion/ProductContentAccordion"
import { ProductContentIcons } from "./Icons/ProductContentIcons"
import { ProductContentIngredients } from "./Ingredients/ProductContentIngredients"
import { ProductContentRecommendations } from "./Recommendations/ProductContentRecommendations"
import { ProductContentReviews } from "./Reviews/ProductContentReviews"
import { ProductContentTicker } from "./Ticker/ProductContentTicker"
import { ProductContentUsage } from "./Usage/ProductContentUsage"
import { ProductContentVideo } from "./Video/ProductContentVideo"

export const ProductContent = withProductContent(
  ({ colour, content, ingredients, product, recommendations, template }): JSX.Element => (
    <>
      {!product?.giftcard && (
        <>
          <ProductContentTicker colour={colour} items={template?.features} />
          <ProductContentVideo colour={colour} content={content} product={product} template={template} />
          <ProductContentIcons colour={colour} id={product?.handle} items={template?.brand} />
          <ProductContentIngredients colour={colour} content={content} ingredients={ingredients} product={product} template={template} />
          <ProductContentUsage colour={colour} items={content?.usage} />
          {(recommendations?.length > 0 || content?.related?.length > 0) && (
            <ProductContentRecommendations id={product?.handle} recommendations={recommendations} related={content?.related} template={template} />
          )}
        </>
      )}
      <ProductContentReviews colour={colour} product={product} template={template} />
      <ProductContentAccordion colour={colour} id={product?.handle} items={content?.faqs} template={template} />
    </>
  )
)
