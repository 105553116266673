import tw, { css, styled } from "twin.macro"

import { StyledRow } from "../../../Styled/Row"
import { H4 } from "../../../Styled/Text"

export const Section = tw.section`py-5.75 md:py-9.25 overflow-hidden`
export const Row = styled(StyledRow)`
  ${tw`transition-transform duration-100`}
  ${({ position }) =>
    position &&
    css`
      will-change: transform;
      transform: translateX(-${position}px);
    `}
`
export const Text = tw(H4)`text-xl`
