import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledContainer } from "../../../Styled/Container"
import { H2, RichText } from "../../../Styled/Text"

export const Item = styled.div`
  ${tw`py-12.5 md:py-34 border-b last:border-b-0`}
  ${({ colour }) => colour && GlobalStyles.border[colour]}
`
export const Container = tw(StyledContainer)`lg:px-40`
export const Title = tw(H2)`text-4xl mb-4 md:mb-0`
export const Content = tw(RichText)`md:text-4.25xl md:leading-tighter`
