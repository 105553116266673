import React, { memo, useMemo, useState, useCallback, useEffect } from "react"
import { useResizeDetector } from "react-resize-detector"

export const withMediaSlides = Component =>
  memo(({ name = "MediaSlides", ...props }) => {
    const { height, ref } = useResizeDetector()

    const [activeIndex, setActiveIndex] = useState(0)
    const [mainSwiper, setMainSwiper] = useState(null)
    const [thumbnailSwiper, setThumbnailSwiper] = useState(null)
    const [seeking, setSeeking] = useState(false)
    const [playedIndex, setPlayedIndex] = useState(new Set())

    const handleInsertPlayedIndex = useCallback(index => {
      setPlayedIndex(prev => new Set(prev.add(index)))
    }, [])

    const handleRemovePlayedIndex = useCallback(index => {
      setPlayedIndex(prev => new Set([...prev].filter(currentIndex => currentIndex !== index)))
    }, [])

    const handleSlideChange = useCallback(index => {
      setActiveIndex(index)
    }, [])

    useEffect(() => {
      // Turn on/off thumbnails for different screen size
      if (thumbnailSwiper) {
        if (height) {
          thumbnailSwiper.enable()
        } else {
          thumbnailSwiper.disable()
        }
      }
    }, [height, thumbnailSwiper])

    useEffect(() => {
      // Disable scrolling and pagination for the main Swiper when dragging forward/back in the video
      if (mainSwiper) {
        if (seeking) {
          mainSwiper.allowTouchMove = false
        } else {
          mainSwiper.allowTouchMove = true
        }
      }
    }, [mainSwiper, seeking])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          activeIndex={activeIndex}
          handleSlideChange={handleSlideChange}
          thumbnailSwiper={thumbnailSwiper}
          setThumbnailSwiper={setThumbnailSwiper}
          setMainSwiper={setMainSwiper}
          thumbnailRef={ref}
          thumbnailHeight={height}
          seeking={seeking}
          setSeeking={setSeeking}
          meatballVisibility={![...playedIndex].includes(activeIndex)}
          handleInsertPlayedIndex={handleInsertPlayedIndex}
          handleRemovePlayedIndex={handleRemovePlayedIndex}
        />
      ),
      [props, activeIndex, handleSlideChange, thumbnailSwiper, ref, height, seeking, playedIndex, handleInsertPlayedIndex, handleRemovePlayedIndex]
    )
  })
