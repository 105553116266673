import React from "react"

import { withProductContentVideo } from "./withProductContentVideo"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { StyledImage } from "../../../Styled/Image"
import {
  Section,
  AspectRatio,
  Content,
  Column,
  Container,
  Title,
  Text,
  PlayCta,
  Images,
  Row,
  Senses,
  Media,
  Video,
  Why,
  SanityImage,
  StyledVideoWrapper,
  ControllerWrapper,
  ControllerCta,
  ControllerPanel,
  ControllerButton,
  InputRange,
  InputRangeSlider,
} from "./styledProductContentVideo"

export const ProductContentVideo = withProductContentVideo(
  ({
    colour,
    content,
    handleClick,
    handleEnd,
    image,
    locales,
    played,
    playing,
    ratio,
    ratioReverse,
    title,
    video,
    sanityVideo,
    muted,
    handleMute,
    handleProgress,
    handleSeekChange,
    handleSeekMouseDown,
    handleSeekMouseUp,
    progress,
    playerRef,
  }): JSX.Element => (
    <Section>
      <Row items={`stretch`} withWrap>
        <Column colour={`${colour}-pastel`} width={`lg:1/2`} />
        <Column colour={colour} width={`lg:1/2`}>
          {image ? (
            <Media className={`group`}>
              {video?.src?.url ? (
                <>
                  {played && (
                    <Video
                      ref={playerRef}
                      alt={video?.alt}
                      volume={1}
                      muted={muted}
                      height={`100%`}
                      onEnded={handleEnd}
                      playing={playing || false}
                      url={video?.src?.url}
                      width={`100%`}
                      onProgress={handleProgress}
                    />
                  )}
                  <Images played={played}>
                    <StyledImage alt={image?.alt || title} fluid={{ ...image, aspectRatio: ratio }} />
                  </Images>

                  <ControllerWrapper>
                    <ControllerCta display={!played}>
                      <PlayCta onClick={handleClick} title={playing ? locales?.additionalPause : locales?.additionalPlay}>
                        <Icon
                          colour={colour}
                          name={playing ? `pause` : `play`}
                          title={playing ? locales?.additionalPause : locales?.additionalPlay}
                          width={21}
                        />
                      </PlayCta>
                    </ControllerCta>

                    <ControllerPanel display={played}>
                      {/** Play button */}
                      <ControllerButton onClick={handleClick} title={playing ? locales?.additionalPause : locales?.additionalPlay}>
                        <Icon colour={`white`} name={playing ? `pause` : `play`} />
                      </ControllerButton>

                      {/** Mute button */}
                      <ControllerButton onClick={handleMute} title={muted ? locales?.additionalPause : locales?.additionalPlay}>
                        <Icon colour={`white`} name={muted ? `mute` : `unmute`} />
                      </ControllerButton>

                      <InputRange>
                        <InputRangeSlider
                          type="range"
                          onChange={handleSeekChange}
                          onTouchMove={handleSeekChange}
                          onMouseDown={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchStart={handleSeekMouseDown}
                          onTouchEnd={handleSeekMouseUp}
                          min={0}
                          max={0.999999}
                          step="any"
                          value={progress}
                          defaultValue="0"
                        />
                      </InputRange>
                    </ControllerPanel>
                  </ControllerWrapper>
                </>
              ) : sanityVideo ? (
                <StyledVideoWrapper paddingRatio={ratioReverse}>
                  <Video
                    ref={playerRef}
                    playsinline={true}
                    volume={1}
                    muted={muted}
                    alt={""}
                    height={`100%`}
                    onEnded={handleEnd}
                    playing={playing || false}
                    url={sanityVideo.video?.link}
                    width={`100%`}
                    onProgress={handleProgress}
                  />
                  <Images played={played}>
                    <SanityImage background={sanityVideo.previewImage?.srcWebp} paddingRatio={ratio} />
                  </Images>
                  <ControllerWrapper>
                    <ControllerCta display={!played}>
                      <PlayCta onClick={handleClick} title={locales?.additionalPlay}>
                        <Icon colour={colour} name={`play-cta`} title={locales?.additionalPlay} width={120} />
                      </PlayCta>
                    </ControllerCta>

                    <ControllerPanel display={played}>
                      {/** Play button */}
                      <ControllerButton onClick={handleClick} title={playing ? locales?.additionalPause : locales?.additionalPlay}>
                        <Icon colour={`white`} name={playing ? `pause` : `play`} />
                      </ControllerButton>

                      {/** Mute button */}
                      <ControllerButton onClick={handleMute} title={muted ? locales?.additionalPause : locales?.additionalPlay}>
                        <Icon colour={`white`} name={muted ? `mute` : `unmute`} />
                      </ControllerButton>

                      <InputRange>
                        <InputRangeSlider
                          type="range"
                          onChange={handleSeekChange}
                          onTouchMove={handleSeekChange}
                          onMouseDown={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchStart={handleSeekMouseDown}
                          onTouchEnd={handleSeekMouseUp}
                          min={0}
                          max={0.999999}
                          step="any"
                          value={progress}
                          defaultValue="0"
                        />
                      </InputRange>
                    </ControllerPanel>
                  </ControllerWrapper>
                </StyledVideoWrapper>
              ) : (
                <Images>
                  <StyledImage alt={image?.alt || title} fluid={{ ...image, aspectRatio: ratio }} />
                </Images>
              )}
            </Media>
          ) : (
            <AspectRatio />
          )}
        </Column>
      </Row>
      <Content colour={`${colour}-pastel`}>
        <Container width={`xl`}>
          <Row gutter={`xl`} items={`stretch`} withWrap>
            <Column gutter={`xl`} width={`lg:1/2`} withScroll>
              {content?.suitability?.length > 0 && (
                <>
                  <Title colour={colour} large withSpacing={`xs`}>
                    {locales?.additionalSuitability}
                  </Title>
                  {content?.suitability?.map(({ id, title }, index: number) => (
                    <Text key={id?.toString()} as={`p`} colour={`grey-darker`} last={index === content?.suitability?.length - 1}>
                      {title}
                    </Text>
                  ))}
                </>
              )}
              {content?.why && (
                <>
                  <Title colour={colour} large withSpacing={`xs`}>
                    {locales?.additionalWhy}
                  </Title>
                  <Why colour={`grey-darker`}>{content?.why}</Why>
                </>
              )}
              {content?.smells?.length > 0 && (
                <>
                  {content?.smells?.map(({ id, image, title }) => (
                    <Senses key={id} gutter={`sm`} items={`center`}>
                      <StyledColumn gutter={`sm`} width={`no-shrink`}>
                        {/* <Image alt={image?.alt || title} fluid={image} /> */}
                        <img className="w-20" src={image?.src} alt={image?.alt} srcSet={image?.srcSet} />
                      </StyledColumn>
                      <StyledColumn gutter={`sm`} width={`grow`}>
                        <Title colour={colour} large withSpacing={`xxxs`}>
                          {locales?.additionalSmells}
                        </Title>
                        <Text colour={`grey-darker`}>{title}</Text>
                      </StyledColumn>
                    </Senses>
                  ))}
                </>
              )}
              {content?.feels?.length > 0 && (
                <>
                  {content?.feels?.map(({ id, image, title }) => (
                    <Senses key={id} gutter={`sm`} items={`center`}>
                      <StyledColumn gutter={`sm`} width={`no-shrink`}>
                        {/* <Image alt={image?.alt || title} fluid={image} /> */}
                        <img className="w-20" src={image?.src} alt={image?.alt} srcSet={image?.srcSet} />
                      </StyledColumn>
                      <StyledColumn gutter={`sm`} width={`grow`}>
                        <Title colour={colour} large withSpacing={`xxxs`}>
                          {locales?.additionalFeels}
                        </Title>
                        <Text colour={`grey-darker`}>{title}</Text>
                      </StyledColumn>
                    </Senses>
                  ))}
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Content>
    </Section>
  )
)
