import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "../../../../Styled/Global"

export const Outer = styled.div`
  ${tw`flex flex-col lg:flex-row lg:items-center mb-14 lg:mb-32 max-w-[1276px] relative`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${css`
    @media (max-width: 1023px) {
      .imageTextDesktop {
        display: none;
      }
    }
    @media (min-width: 1024px) {
      .imageTextMobile {
        display: none;
      }

      .proto-media__container {
        width: 55%;
      }
    }
    .video-action__btn {
      z-index: 4;
    }
  `}
`
export const PreTitle = tw.p`text-base lg:text-lg pb-2`
export const Title = tw.p`text-3xl lg:text-4xl pb-2`
export const Content = tw.p`text-sm lg:text-base`
export const ContentContainer = tw.div`lg:px-12 xl:px-20 p-4 lg:w-[45%]`
