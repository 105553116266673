import React, { memo, useMemo } from "react"

import { useCore } from "../../../../hooks/useCore"

export const withProductContentUsage = Component =>
  memo(({ name = "ProductContentUsage", colour, items: rawItems }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const items = rawItems?.map(({ content, id, title }) => ({
      content: sanityContent(content),
      id,
      title,
    }))

    Component.displayName = name
    return useMemo(() => (items?.length > 0 ? <Component colour={colour} items={items} /> : null), [colour, items])
  })
