import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "../../Styled/Global"
import { StyledBreadcrumbs, StyledBreadcrumbsContainer } from "../../Styled/Breadcrumb"
import { StyledLink } from "../../Styled/Button"
import { StyledContainer } from "../../Styled/Container"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { Overline, P, Small, RichText } from "../../Styled/Text"

export const Section = tw.section`relative`
export const Details = tw.div`w-full`
export const Content = styled.div`
  ${tw`lg:absolute lg:inset-0 lg:flex lg:items-center`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
`
export const Price = tw(P)`font-bold text-xl md:font-medium leading-none md:leading-none md:text-4.25xl mr-3`
export const ComparedPrice = tw.span`line-through mr-2`
export const Container = tw(StyledContainer)`h-full`
export const Ingredients = tw(StyledLink)`text-sm md:text-xs font-normal md:font-medium`
export const DescriptionContent = tw(RichText)`pr-2 lg:pr-0`
export const DescriptionRow = tw(StyledRow)`lg:justify-start`
export const Pricing = tw(StyledRow)`mb-8 flex-wrap`
export const Description = tw(Overline)`text-base`
export const Row = tw(StyledRow)`h-full`
export const Afterpay = tw(Small)`font-normal whitespace-nowrap`
export const AfterpayIcon = tw.div`w-17.5 md:w-20 ml-2 md:ml-1.5 -mb-0.25 md:-mb-0.5`
export const Breadcrumbs = tw(StyledBreadcrumbs)`lg:mb-8`
export const BreadcrumbsContainer = tw(StyledBreadcrumbsContainer)`lg:static lg:top-auto lg:text-grey-darkest lg:px-0 lg:w-full`
export const Column = styled(StyledColumn)`
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ withScroll }) => withScroll && tw`lg:h-full lg:overflow-x-hidden lg:overflow-y-auto py-5.25 md:py-12 lg:py-6.25 lg:px-5 lg:-mx-5`}
  ${({ withScroll }) =>
    withScroll &&
    css`
      &::-webkit-scrollbar {
        ${tw`w-1`}
      }
      &::-webkit-scrollbar-thumb {
        ${tw`bg-grey-light rounded-3xl`}
      }
    `}
`
