import React from "react"

import { withProtoImageText } from "./withProtoImageText"
import { MediaItem } from "../../../Detail/Image/Slides/MediaItem"
import { Outer, PreTitle, Title, Content, ContentContainer } from "./styledProtoImageText"

export const ProtoImageText = withProtoImageText(
  ({ image, preTitle, title, content, colour, height, width, ratioReverse }): JSX.Element => (
    <>
      <Outer colour={`${colour}-pastel`} width={width || 0}>
        <div className="proto-media__container">
          <MediaItem item={image[0]} key={image?.[0]?.video?.desktop?.link} prototype={true} ratio={width / height} ratioReverse={ratioReverse} />
        </div>
        <ContentContainer>
          <PreTitle>{preTitle}</PreTitle>
          <Title>{title}</Title>
          <Content>{content}</Content>
        </ContentContainer>
      </Outer>
    </>
  )
)
