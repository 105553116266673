import React from "react"
import { Link } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"

import { Icon } from "../../../Icon/Icon"
import { withProtoProductDetail } from "./withPrototypeProductDetail"
import { ProductDetailVariants } from "../../Detail/Variants/ProductDetailVariants"
import { ProtoProductDetailImage } from "./Image/ProtoProductDetailImage"
import { ProductDetailAddToCart } from "../../Detail/AddToCart/ProductDetailAddToCart"
import { ProtoProductContent } from "../Content/ProtoProductContent"
import { ProtoImageText } from "./ImageText/ProtoImageText"
import { Accordion } from "../../../Sections/Accordion/Accordion"

import { StyledBreadcrumb } from "../../../Styled/Breadcrumb"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import {
  Breadcrumbs,
  BreadcrumbsContainer,
  TopContainer,
  ImageContainer,
  DetailsContainer,
  Pricing,
  Price,
  ComparedPrice,
  Afterpay,
  AfterpayIcon,
  OuterContainer,
  Ingredients,
  DescriptionRow,
  DescriptionContent,
  BottomContainer,
  StyledPageLink,
} from "./styledPrototypeProductDetail"
import { H4, P } from "../../../Styled/Text"

export const ProtoProductDetail = withProtoProductDetail(
  ({
    product,
    breadcrumb,
    breadcrumbs,
    handleVisibility,
    formattedPrice,
    formattedCompareAtPrice,
    selectedVariant,
    formattedInstallmentPrice,
    onSale,
    template,
    loading,
    grouped,
    bar,
    quantity,
    handleQuantity,
    ingredients,
    add,
    items,
    handleScroll,
    hasIngredients,
    recommendations,
    link,
  }): JSX.Element => (
    <OuterContainer>
      <TopContainer>
        <ImageContainer>
          <BreadcrumbsContainer prototype={true}>
            <Breadcrumbs>
              <StyledBreadcrumb as={Link} title={breadcrumb?.title} to={breadcrumb?.url}>
                {breadcrumb?.title}
              </StyledBreadcrumb>
              {breadcrumbs?.map((breadcrumb: any) => (
                <StyledBreadcrumb
                  key={breadcrumb?.title?.toString()}
                  as={breadcrumb?.url ? Link : null}
                  title={breadcrumb?.title}
                  to={breadcrumb?.url}
                >
                  {breadcrumb?.title}
                </StyledBreadcrumb>
              ))}
              <StyledBreadcrumb>{product?.title}</StyledBreadcrumb>
            </Breadcrumbs>
          </BreadcrumbsContainer>
          <ProtoProductDetailImage product={product} prototype={true} />
        </ImageContainer>
        <DetailsContainer ref={add}>
          <VisibilitySensor offset={{ top: 150 }} onChange={handleVisibility} partialVisibility>
            <div>
              {link && (
                <StyledPageLink as={Link} title={link?.title} to={link?.url} proto>
                  {link?.title}
                </StyledPageLink>
              )}{" "}
              <H4 as={`h1`} uppercase withSpacing={`xxs`}>
                {product?.title}
              </H4>
              {product?.type && (
                <P large withSpacing={`xs`}>
                  {product?.type}
                </P>
              )}
              <DescriptionRow gutter={`sm`} items={`center`} withSpacing={`xs`}>
                {hasIngredients && (
                  <StyledColumn gutter={`sm`} width={`no-shrink`}>
                    <Ingredients colour={"black"} onClick={handleScroll} title={template?.additionalIngredientsButton}>
                      {template?.additionalIngredientsButton}
                    </Ingredients>
                  </StyledColumn>
                )}
              </DescriptionRow>
              <>
                {product?.giftcard && onSale ? (
                  <Pricing items={`end`}>
                    <StyledColumn width={`no-shrink`}>
                      <Price large>
                        <ComparedPrice>&nbsp;{formattedPrice}&nbsp;</ComparedPrice>
                        {formattedCompareAtPrice}
                      </Price>
                    </StyledColumn>
                    <StyledColumn width={`grow`}>
                      <StyledRow justify={`start`} items={`end`}>
                        <Afterpay>{template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)}</Afterpay>
                        <AfterpayIcon>
                          <Icon name={`afterpay`} title={template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)} />
                        </AfterpayIcon>
                      </StyledRow>
                    </StyledColumn>
                  </Pricing>
                ) : (
                  <Pricing items={`end`}>
                    <StyledColumn width={`no-shrink`}>
                      <Price large>
                        {+selectedVariant?.compareAtPrice?.amount > +selectedVariant?.price?.amount ||
                        +selectedVariant?.compareAtPriceV2?.amount > +selectedVariant?.priceV2?.amount ? (
                          <ComparedPrice>&nbsp;{formattedCompareAtPrice}&nbsp;</ComparedPrice>
                        ) : null}
                        {formattedPrice}
                      </Price>
                    </StyledColumn>
                    <StyledColumn width={`grow`}>
                      <StyledRow justify={`start`} items={`end`}>
                        <Afterpay>{template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)}</Afterpay>
                        <AfterpayIcon>
                          <Icon name={`afterpay`} title={template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)} />
                        </AfterpayIcon>
                      </StyledRow>
                    </StyledColumn>
                  </Pricing>
                )}
                <StyledRow withWrap items={`end`} prototype={true}>
                  {!loading && (
                    <StyledColumn width={`2/3`}>
                      <ProductDetailVariants grouped={grouped} product={product} prototype={true} />
                    </StyledColumn>
                  )}
                  <StyledColumn width={`1/3`}>
                    <ProductDetailAddToCart
                      colour={product?.colour}
                      component={`quantity`}
                      handleChange={handleQuantity}
                      quantity={quantity}
                      template={template}
                      bar={bar}
                      prototype={true}
                      product={product}
                    />
                  </StyledColumn>
                  <ProductDetailAddToCart
                    colour={product?.colour}
                    component={`button`}
                    loading={loading}
                    product={product}
                    quantity={quantity}
                    template={template}
                    bar={bar}
                    prototype={true}
                  />
                </StyledRow>
                <DescriptionContent colour={`grey-darker`}>{product?.description}</DescriptionContent>
              </>
            </div>
          </VisibilitySensor>
          {items.length > 0 && <Accordion items={items} prototype={true} />}
        </DetailsContainer>
      </TopContainer>
      <BottomContainer>
        {product?.content?.protoImageText?.image && <ProtoImageText product={product} />}
        <ProtoProductContent ingredients={ingredients} product={product} template={template} recommendations={recommendations} />
      </BottomContainer>
    </OuterContainer>
  )
)
