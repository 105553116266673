import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { withProductContentTicker } from "./withProductContentTicker"
import { StyledColumn } from "../../../Styled/Column"
import { Section, Row, Text } from "./styledProductContentTicker"

export const ProductContentTicker = withProductContentTicker(
  ({ colour, handleVisibility, items, position, visible }): JSX.Element => (
    <VisibilitySensor onChange={handleVisibility} partialVisibility={true}>
      <Section>
        <Row gutter={`xs`} items={`center`} position={visible ? position : null}>
          {[...items, ...items, ...items]?.map((item: string, index: number) => (
            <StyledColumn key={`${item}-${index}`} gutter={`xs`} width={`no-shrink`}>
              <Text colour={colour}>{item}</Text>
            </StyledColumn>
          ))}
        </Row>
      </Section>
    </VisibilitySensor>
  )
)
