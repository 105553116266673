import React from "react"

import { withProductDetailAddToCart } from "./withProductDetailAddToCart"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { Overline } from "../../../Styled/Text"
import { Button, Notify, Link, Row } from "./styledProductDetailAddToCart"
import { ProductBar } from "../../Bar/ProductBar"

export const ProductDetailAddToCart = withProductDetailAddToCart(
  ({
    adding,
    colour,
    component,
    handleAdd,
    handleChange,
    handleNotify,
    loading,
    locales,
    product,
    quantity,
    selectedVariant,
    clientId,
    bar,
    prototype = false,
  }): JSX.Element => (
    <>
      {component === `quantity` ? (
        !product?.giftcard && (
          <Row justify={prototype ? `prototype` : `center`} items={`center`}>
            <StyledColumn width={`no-shrink`}>
              <Link
                colour={`grey-darker`}
                disabled={quantity === 1}
                title={locales?.additionalQuantityRemove}
                onClick={() => handleChange(quantity - 1)}
              >
                <Icon name={`minus`} width={13} />
              </Link>
            </StyledColumn>
            <StyledColumn width={`1/3`}>
              <Overline align={`center`} colour={prototype ? `black` : colour} large title={quantity}>
                {quantity}
              </Overline>
            </StyledColumn>
            <StyledColumn width={`no-shrink`}>
              <Link colour={`grey-darker`} title={locales?.additionalQuantityAdd} onClick={() => handleChange(quantity + 1)}>
                <Icon name={`plus`} width={13} />
              </Link>
            </StyledColumn>
          </Row>
        )
      ) : component === `button` ? (
        <>
          <Button
            colour={
              prototype
                ? "black"
                : loading || selectedVariant?.availableForSale
                ? product?.colour?.includes(`orange`)
                  ? `purple`
                  : `orange`
                : `white`
            }
            disabled={adding || loading}
            full
            onClick={loading || selectedVariant?.availableForSale ? handleAdd : handleNotify}
            size={`primary`}
            title={locales?.additionalNotify}
            data-clientid={clientId ? clientId : null}
            notify={!loading && !selectedVariant?.availableForSale ? `sm` : null}
          >
            {loading || selectedVariant?.availableForSale ? locales?.additionalAddToCart : locales?.additionalNotify}
          </Button>
          {!loading && !selectedVariant?.availableForSale && (
            <Notify align={`center`} colour={`grey-darker`}>
              {locales?.additionalNotifyTitle}
            </Notify>
          )}
        </>
      ) : null}
      <ProductBar
        colour={
          prototype ? "black" : loading || selectedVariant?.availableForSale ? (product?.colour?.includes(`orange`) ? `purple` : `orange`) : `white`
        }
        handleClick={loading || selectedVariant?.availableForSale ? handleAdd : handleNotify}
        loading={loading}
        active={bar}
        title={loading || selectedVariant?.availableForSale ? locales?.additionalAddToCart : locales?.additionalNotify}
        content={loading || selectedVariant?.availableForSale ? locales?.additionalAddToCart : locales?.additionalNotify}
        product={product}
        clientId={clientId}
        notify={!loading && !selectedVariant?.availableForSale ? `sm` : null}
      />
    </>
  )
)
