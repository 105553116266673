import React, { memo, useMemo, useEffect, useState, useRef } from "react"
import { useDom } from "../../../../../hooks/useDom"
import { useImage } from "../../../../../hooks/useImage"

export const withProtoImageText = Component =>
  memo(({ name = "ProtoImageText", product }: any) => {
    Component.displayName = name
    const { dom } = useDom()
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const protoImageRef = useRef(null)
    const ratioReverse = dom?.isMedium ? 10 / 9 : 83 / 75
    const { getResponsiveImage } = useImage()

    useEffect(() => {
      if (dom?.width) {
        const video = product?.content?.protoImageText?.image?.[0]?.video || {}
        if (video) {
          if (dom?.width < 1024) {
            const refWidth = protoImageRef.current?.getBoundingClientRect()?.width
            const ratio = video?.mobileHeight / video?.mobileWidth
            const containerHeight = refWidth * ratio
            setHeight(containerHeight || video?.mobileHeight)
            setWidth(refWidth || dom?.width)
          }
          if (dom?.width >= 1024 && protoImageRef.current) {
            const refWidth = protoImageRef.current?.getBoundingClientRect()?.width
            const containerWidth = refWidth * 0.55
            const ratio = video?.desktopHeight / video?.desktopWidth
            const containerHeight = containerWidth * ratio
            setWidth(containerWidth || video?.desktopWidth)
            setHeight(containerHeight || video?.desktopHeight)
          }
        }
      }
    }, [dom, product?.content?.protoImageText?.image, protoImageRef])

    const mediaItem = useMemo(
      () =>
        product?.content?.protoImageText?.image && Array.isArray(product?.content?.protoImageText?.image)
          ? product.content.protoImageText.image.reduce((result, media) => {
              if (media?._type === "imageResponsive") {
                const responsiveImage = getResponsiveImage(media, { desktop: { maxWidth: 1000 }, mobile: { maxWidth: 800 } })
                const image = dom?.isMedium ? responsiveImage.desktop : responsiveImage.mobile || responsiveImage.desktop
                result.push({ image })
              } else if (media?.video?.enabled) {
                const responsivePreviewImage = getResponsiveImage(media?.previewImage, { desktop: { maxWidth: 1000 }, mobile: { maxWidth: 800 } })
                result.push({
                  sanityVideo: {
                    previewImage: dom?.isMedium ? responsivePreviewImage.desktop : responsivePreviewImage.mobile,
                    video: dom?.isMedium ? media?.video?.desktop : media?.video?.mobile,
                  },
                })
              }

              return result
            }, [])
          : [],
      [dom?.isMedium, getResponsiveImage, product?.content]
    )

    return useMemo(
      () => (
        <Component
          image={mediaItem}
          preTitle={product?.content?.protoImageText?.preTitle}
          title={product?.content?.protoImageText?.title}
          content={product?.content?.protoImageText?.content}
          colour={product?.colour}
          height={height}
          width={width}
          ratioReverse={ratioReverse}
        />
      ),
      [mediaItem, product, height, width, ratioReverse]
    )
  })
