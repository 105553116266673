import React from "react"

import { MediaSlides } from "../../../Detail/Image/Slides/MediaSlides"
import { MediaItem } from "../../../Detail/Image/Slides/MediaItem"
import { withProtoProductDetailImage } from "./withProtoProductDetailImage"
import { Icon } from "../../../../Icon/Icon"
import { StyledImage } from "../../../../Styled/Image"
import { AspectRatio, Images, Wishlist } from "./styledProtoProductDetailImage"

export const ProtoProductDetailImage = withProtoProductDetailImage(
  ({ colour, handleWishlist, image, mediaGroup, locales, ratio, ratioReverse, title, wishlist, showNavigation, prototype, bounds }) => (
    <Images>
      {mediaGroup && bounds.width < 1024 ? (
        <MediaSlides
          id={"product-image-details"}
          items={mediaGroup}
          ratio={ratio}
          locales={locales}
          ratioReverse={ratioReverse}
          showNavigation={showNavigation}
          colour={colour}
          wishlist={wishlist}
          handleWishlist={handleWishlist}
          prototype={prototype}
        />
      ) : (
        mediaGroup &&
        bounds.width >= 1024 && (
          <>
            <StyledImage alt={image?.alt || title} fluid={{ ...image, aspectRatio: ratio }} />
            <Wishlist
              active={wishlist}
              colour={`white`}
              onClick={event => handleWishlist(event)}
              title={wishlist ? locales?.additionalRemoveFromWishlist : locales?.additionalAddToWishlist}
              prototype={prototype}
            >
              <Icon fill={wishlist ? `auto` : `none`} name={wishlist ? `heart` : `heart-outline`} />
            </Wishlist>
            {mediaGroup?.slice(1)?.map((media, index) => (
              <MediaItem item={media} key={`${index}_${media?.id}`} prototype={prototype} ratio={ratio} ratioReverse={ratioReverse} />
            ))}
          </>
        )
      )}
    </Images>
  )
)
