import React from "react"

import { withProductContentIngredients } from "./withProductContentIngredients"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import { StyledImage } from "../../../Styled/Image"
import { P } from "../../../Styled/Text"
import {
  Section,
  AspectRatio,
  Content,
  Column,
  Container,
  Title,
  Ingredients,
  Ingredient,
  IconPlaceholder,
  IngredientText,
  IngredientTooltip,
  Close,
  Banned,
  BannedIcon,
  Recipe,
  Row,
  IngredientSvg,
} from "./styledProductContentIngredients"

export const ProductContentIngredients = withProductContentIngredients(
  ({ active, colour, content, handleClick, handleItem, image, ingredients, locales, open, ratio, title, prototype }): JSX.Element => (
    <Section onClick={(event: any) => handleItem(event, null)} ref={ingredients} prototype={prototype}>
      <Row items={`stretch`} withWrap>
        <Column colour={`${colour}-pastel`} width={`lg:1/2`} />
        <Column colour={colour} withIndex width={`lg:1/2`}>
          {image ? <StyledImage alt={image?.alt || title} fluid={{ ...image, aspectRatio: ratio }} /> : <AspectRatio />}
        </Column>
      </Row>
      <Content colour={`${colour}-pastel`}>
        <Container width={`xl`}>
          <Row gutter={`xl`} items={`stretch`} withWrap>
            <Column colour={`${colour}-pastel`} gutter={`xl`} width={`lg:1/2`} withScroll>
              {content?.recipe?.length > 0 && (
                <>
                  <Title colour={colour} large withSpacing={`xs`}>
                    {locales?.additionalRecipe}
                  </Title>
                  {content?.recipe?.map(({ description, icon, id, title, iconFile }, index: number) => (
                    <Recipe key={`${id?.toString()}-${index}`} gutter={`sm`} items={`center`} last={index === content?.recipe?.length - 1}>
                      <StyledColumn gutter={`sm`} width={`no-shrink`}>
                        {iconFile ? (
                          <IngredientSvg colour={!prototype ? colour : "black"} dangerouslySetInnerHTML={{ __html: iconFile }} />
                        ) : icon ? (
                          <Icon colour={!prototype ? colour : "black"} name={`brand-${icon}`} height={40} width={40} />
                        ) : (
                          <IconPlaceholder />
                        )}
                      </StyledColumn>
                      <StyledColumn gutter={`sm`} width={`grow`}>
                        <Ingredient
                          as={description ? `button` : null}
                          onClick={(event: any) => handleItem(event, id)}
                          onMouseEnter={(event: any) => handleItem(event, id)}
                          onMouseLeave={(event: any) => handleItem(event, id)}
                        >
                          <IngredientText large underline={description && active !== id}>
                            {title}
                          </IngredientText>
                          {description && active === id && (
                            <IngredientTooltip>
                              <P colour={`grey-darker`}>
                                <strong>{title}</strong>
                              </P>
                              <P colour={`grey-darker`}>{description}</P>
                              <Close onClick={(event: any) => handleItem(event, id)}>
                                <Icon colour={`grey-darker`} name={`close`} width={12} />
                              </Close>
                            </IngredientTooltip>
                          )}
                        </Ingredient>
                      </StyledColumn>
                    </Recipe>
                  ))}
                </>
              )}
              {content?.ingredients?.length > 0 && (
                <>
                  <Title colour={colour} large withSpacing={`xs`}>
                    {locales?.additionalIngredients}
                  </Title>
                  <Ingredients>
                    {content?.ingredients?.map(({ description, id, title }, index: number) => (
                      <Ingredient
                        key={`${id?.toString()}-${index}`}
                        as={description ? `button` : null}
                        inline
                        onClick={(event: any) => handleItem(event, id)}
                        onMouseEnter={(event: any) => handleItem(event, id)}
                        onMouseLeave={(event: any) => handleItem(event, id)}
                      >
                        <IngredientText underline={description && active !== id}>
                          {title}
                          {index !== content?.ingredients?.length - 1 ? `, ` : ``}
                        </IngredientText>
                        {description && active === id && (
                          <IngredientTooltip>
                            <P colour={`grey-darker`}>
                              <strong>{title}</strong>
                            </P>
                            <P colour={`grey-darker`}>{description}</P>
                            <Close onClick={(event: any) => handleItem(event, id)}>
                              <Icon colour={`grey-darker`} name={`close`} width={12} />
                            </Close>
                          </IngredientTooltip>
                        )}
                      </Ingredient>
                    ))}
                  </Ingredients>
                </>
              )}
              {content?.banned && (
                <>
                  <Title as={`button`} colour={!prototype ? colour : "black"} large onClick={handleClick} title={locales?.additionalBanned}>
                    {locales?.additionalBanned}
                    <BannedIcon>
                      <Icon name={open ? `minus` : `plus`} width={12} />
                    </BannedIcon>
                  </Title>
                  {open && <Banned>{content?.banned}</Banned>}
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Content>
    </Section>
  )
)
