import React, { memo, useCallback, useMemo, useState, useEffect } from "react"

import { useDom } from "../../../../../hooks/useDom"
import { useImage } from "../../../../../hooks/useImage"
import { useTemplate } from "../../../../../hooks/useTemplate"
import { useWishlist } from "../../../../../hooks/useWishlist"

export const withProtoProductDetailImage = Component =>
  memo(({ name = "ProtoProductDetailImage", product, prototype = false }: any) => {
    const { dom } = useDom()
    const { global: locales } = useTemplate()
    const { addToWishlist, deleteFromWishlist, existsInWishlist } = useWishlist()
    const { getResponsiveImage } = useImage()
    const [bounds, setBounds] = useState<Bounds>({})

    const image = product?.images?.[2] || product?.images?.[0]

    const ratio = dom?.isMedium ? 9 / 10 : 75 / 83
    const ratioReverse = dom?.isMedium ? 10 / 9 : 83 / 75

    const title = product?.title
    const wishlist = existsInWishlist(product?.id)
    const showNavigation = dom?.isMedium

    useEffect(() => setBounds({ width: window.innerWidth }), [setBounds])

    const extraMedia = useMemo(
      () =>
        product?.content && Array.isArray(product.content.extraMedia)
          ? product.content.extraMedia.reduce((result, media) => {
              if (media?._type === "imageResponsive") {
                const responsiveImage = getResponsiveImage(media, { desktop: { maxWidth: 1000 }, mobile: { maxWidth: 800 } })
                const image = dom?.isMedium ? responsiveImage.desktop : responsiveImage.mobile || responsiveImage.desktop
                result.push({ image })
              } else if (media?.video?.enabled) {
                const responsivePreviewImage = getResponsiveImage(media?.previewImage, { desktop: { maxWidth: 1000 }, mobile: { maxWidth: 800 } })
                result.push({
                  sanityVideo: {
                    previewImage: dom?.isMedium ? responsivePreviewImage.desktop : responsivePreviewImage.mobile,
                    video: dom?.isMedium ? media?.video?.desktop : media?.video?.mobile,
                  },
                })
              }

              return result
            }, [])
          : [],
      [dom?.isMedium, getResponsiveImage, product?.content]
    )

    const mediaGroup = useMemo(() => extraMedia.length && image && [{ shopify: image }, ...extraMedia], [extraMedia, image])

    const handleWishlist = useCallback(
      event => {
        event.preventDefault()

        if (wishlist) {
          deleteFromWishlist(product?.id)
        } else {
          addToWishlist({
            ...product,
          })
        }
      },
      [addToWishlist, deleteFromWishlist, product, wishlist]
    )

    Component.displayName = name
    return useMemo(
      () =>
        dom?.height ? (
          <Component
            colour={product?.colour}
            handleWishlist={handleWishlist}
            image={image}
            mediaGroup={mediaGroup}
            locales={locales}
            ratio={ratio}
            ratioReverse={ratioReverse}
            title={title}
            wishlist={wishlist}
            showNavigation={showNavigation}
            prototype={prototype}
            bounds={bounds}
          />
        ) : null,
      [
        dom?.height,
        product?.colour,
        handleWishlist,
        image,
        mediaGroup,
        locales,
        ratio,
        ratioReverse,
        title,
        wishlist,
        showNavigation,
        prototype,
        bounds,
      ]
    )
  })
