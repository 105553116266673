import React from "react"

import { withProductContentRecommendations } from "./withProductContentRecommendations"
import { FeaturedProductsItem } from "../../../Sections/FeaturedProducts/Item/FeaturedProductsItem"
import { FeaturedProductsSlides } from "../../../Sections/FeaturedProducts/Slides/FeaturedProductsSlides"
import { Section } from "../../../Sections/FeaturedProducts/styledFeaturedProducts"

export const ProductContentRecommendations = withProductContentRecommendations(
  ({ autoplay, bounds, colour, id, items, locales }): JSX.Element => (
    <Section>
      {items?.length > 1 ? (
        <FeaturedProductsSlides
          autoplay={autoplay}
          colour={colour}
          id={id}
          items={items}
          locales={locales}
          pretitle={locales?.additionalRelated}
          top={bounds?.height}
        />
      ) : (
        <FeaturedProductsItem item={items?.[0]} pretitle={locales?.additionalRelated} top={bounds?.height} />
      )}
    </Section>
  )
)
