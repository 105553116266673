import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import axios from "axios"

import { useApp } from "../../../../hooks/useApp"
import { useDom } from "../../../../hooks/useDom"
import { useSettings } from "../../../../hooks/useSettings"

export const withProductContentReviews = Component =>
  memo(({ name = "ProductContentReviews", colour, product, template }: any) => {
    const {
      config: {
        services: { stamped },
      },
    } = useApp()
    const { dom } = useDom()
    const [active, setActive] = useState(false)
    const [fetched, setFetched] = useState(false)
    const [reviews, setReviews] = useState(null)
    const [page, setPage] = useState(1)
    const [swiper, setSwiper] = useState(null)
    const { organisation } = useSettings()

    const items = reviews?.map((review: any) => ({
      ...review,
    }))

    const fetchReviews = useCallback(
      async (page = 1, reviews = []) => {
        setFetched(true)

        const { data } = await axios.get(`//stamped.io/api/widget/reviews`, {
          params: {
            page,
            take: 50,
            minRating: 1,
            productId: product?.legacyId,
            storeUrl: stamped?.storeUrl,
            apiKey: stamped?.apiKey,
          },
        })

        const allReviews = [...reviews, ...(data?.data || [])]

        if (data?.total && allReviews?.length < data?.total) {
          await fetchReviews(data?.page + 1, allReviews)
        } else {
          setReviews(allReviews)
        }
      },
      [product, setFetched, setReviews, stamped]
    )

    const handleClick = useCallback(() => setActive((prevState: boolean) => !prevState), [setActive])
    const handleChange = useCallback(
      (index: number) => (swiper ? swiper.slideTo(index * (dom?.isLarge ? 4 : dom?.isMedium ? 2 : 1)) : null),
      [dom, swiper]
    )
    const handleInit = useCallback(swiper => setSwiper(swiper), [])
    const handleSlideChange = useCallback(swiper => setPage(swiper?.realIndex / (dom?.isLarge ? 4 : dom?.isMedium ? 2 : 1)), [dom, setPage])

    useEffect(() => {
      if (fetched) return
      fetchReviews()
    }, [fetchReviews, fetched])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={active}
          colour={colour}
          handleClick={handleClick}
          handleChange={handleChange}
          handleInit={handleInit}
          handleSlideChange={handleSlideChange}
          items={items}
          page={page}
          product={product}
          template={template}
          shopName={organisation?.title}
        />
      ),
      [active, colour, handleClick, handleChange, handleInit, handleSlideChange, items, page, product, template, organisation]
    )
  })
