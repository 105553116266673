import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel } from "swiper"

import { withProductContentReviews } from "./withProductContentReviews"
import { Icon } from "../../../Icon/Icon"
import { ProductContentReviewsForm } from "./Form/ProductContentReviewsForm"
import { ProductContentReviewsPagination } from "./Pagination/ProductContentReviewsPagination"
import { StyledContainer } from "../../../Styled/Container"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { H4, H5, P } from "../../../Styled/Text"
import { Section, Button, Slides, Review, Header, Top, Bottom, Author, Star, Reply } from "./styledProductContentReviews"

SwiperCore.use([Mousewheel])

export const ProductContentReviews = withProductContentReviews(
  ({ active, colour, handleClick, handleChange, handleInit, handleSlideChange, items, page, product, template, shopName }): JSX.Element => (
    <Section>
      <StyledContainer width={`xl`}>
        <Header gutter items={items?.length === 0 ? `start` : `center`}>
          <StyledColumn gutter width={`1/2`}>
            <H4 withSpacing={items?.length === 0 && `sm`}>{template?.additionalReviews}</H4>
            {items?.length === 0 ? <P>{template?.additionalReviewsEmpty}</P> : null}
          </StyledColumn>
          <StyledColumn gutter justify={`end`} width={`1/2`}>
            <Button colour={`${colour}-outline`} onClick={handleClick} size={`secondary`} title={template?.additionalReviewsWrite}>
              {template?.additionalReviewsWrite}
            </Button>
          </StyledColumn>
        </Header>
        {items?.length > 4 ? (
          <>
            <Slides>
              <Swiper
                autoHeight
                breakpoints={{
                  768: { slidesPerGroup: 2, slidesPerView: 2 },
                  1024: { slidesPerGroup: 4, slidesPerView: 4 },
                }}
                loop
                mousewheel={false}
                onSlideChange={handleSlideChange}
                onSwiper={handleInit}
                slidesPerGroup={1}
                slidesPerView={1}
                spaceBetween={50}
              >
                {items?.map(({ author, id, location, reviewMessage, reviewRating, reviewTitle, reviewReply }) => (
                  <SwiperSlide key={id?.toString()}>
                    <Review>
                      <Top>
                        <H5 colour={colour} withSpacing={`xs`}>
                          {reviewTitle}
                        </H5>
                        <P colour={`grey-darker`}>{reviewMessage}</P>
                      </Top>
                      <Bottom>
                        <Author colour={`grey-darker`} uppercase>
                          {author}
                          {author && location && ` — `}
                          {location}
                        </Author>
                        <StyledRow justify={`start`}>
                          {Array.from(Array(5).keys()).map((_, i) => (
                            <Star key={i}>
                              <Icon colour={i < reviewRating ? colour : `${colour}-pastel`} name={`star`} width={16} />
                            </Star>
                          ))}
                        </StyledRow>
                      </Bottom>
                      {reviewReply && (
                        <Reply colour={`grey-darker`}>
                          {reviewReply}
                          {shopName && ` - ${shopName}`}
                        </Reply>
                      )}
                    </Review>
                  </SwiperSlide>
                ))}
              </Swiper>
              <ProductContentReviewsPagination active={page} handleClick={handleChange} pages={items?.length} />
            </Slides>
          </>
        ) : items?.length > 0 ? (
          <StyledRow gutter justify={`start`} withWrap>
            {items?.map(({ author, id, location, reviewMessage, reviewRating, reviewTitle, reviewReply }) => (
              <StyledColumn key={id?.toString()} gutter width={`md:1/2 lg:1/4`}>
                <Review>
                  <Top>
                    <H5 colour={colour} withSpacing={`xs`}>
                      {reviewTitle}
                    </H5>
                    <P colour={`grey-darker`}>{reviewMessage}</P>
                  </Top>
                  <Bottom>
                    <Author colour={`grey-darker`} uppercase>
                      {author}
                      {author && location && ` — `}
                      {location}
                    </Author>
                    <StyledRow justify={`start`}>
                      {Array.from(Array(5).keys()).map((_, i) => (
                        <Star key={i}>
                          <Icon colour={i < reviewRating ? colour : `${colour}-pastel`} name={`star`} width={16} />
                        </Star>
                      ))}
                    </StyledRow>
                  </Bottom>
                  {reviewReply && (
                    <Reply colour={`grey-darker`}>
                      {reviewReply}
                      {shopName && ` - ${shopName}`}
                    </Reply>
                  )}
                </Review>
              </StyledColumn>
            ))}
          </StyledRow>
        ) : null}
      </StyledContainer>
      <ProductContentReviewsForm active={active} handleActive={handleClick} product={product} template={template} />
    </Section>
  )
)
