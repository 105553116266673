import React, { memo, useMemo } from "react"

export const withProductContent = Component =>
  memo(({ name = "ProductContent", ingredients, product, recommendations, template }: any) => {
    const { colour, content, ...item } = product

    Component.displayName = name
    return useMemo(
      () => (
        <Component colour={colour} content={content} ingredients={ingredients} product={item} recommendations={recommendations} template={template} />
      ),
      [colour, content, ingredients, item, recommendations, template]
    )
  })
