import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "../../Styled/Global"
import { StyledButton } from "../../Styled/Button"

export const Bar = styled.section`
  ${tw`fixed bottom-0 inset-x-0 shadow-md lg:shadow-lg py-5 lg:py-4 z-5 invisible transform translate-y-full lg:-translate-y-full transition-all duration-200 lg:bottom-auto`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ active }) => active && tw`visible translate-y-0 delay-500 lg:translate-y-0`}
  ${({ top }) =>
    top &&
    css`
      @media (min-width: 1024px) {
        top: ${top}px;
      }
    `}
`
export const Button = styled(StyledButton)`
  ${tw`md:w-75`}
  ${({ active }) => !active && tw`opacity-0`}
  ${({ bgColour }) => bgColour && tw`bg-white`}
`
