import React from "react"

import { ProductContent } from "./Content/ProductContent"
import { ProductDetail } from "./Detail/ProductDetail"
import { ProtoProductDetail } from "./Prototype/Detail/PrototypeProductDetail"
import { withProduct } from "./withProduct"

export const Product = withProduct(
  ({ add, bar, grouped, ingredients, loading, product, recommendations, setBar, template, isPrototype }): JSX.Element => (
    <>
      {!isPrototype ? (
        <>
          <ProductDetail
            add={add}
            bar={bar}
            grouped={grouped}
            ingredients={ingredients}
            loading={loading}
            product={product}
            setBar={setBar}
            template={template}
          />
          <ProductContent ingredients={ingredients} product={product} recommendations={recommendations} template={template} />
        </>
      ) : (
        <>
          <ProtoProductDetail
            add={add}
            bar={bar}
            grouped={grouped}
            ingredients={ingredients}
            loading={loading}
            product={product}
            setBar={setBar}
            template={template}
            recommendations={recommendations}
          />
        </>
      )}
    </>
  )
)
