import React, { memo, useMemo } from "react"

import { useShopify } from "../../../../../hooks/useShopify"
import { useTemplate } from "../../../../../hooks/useTemplate"

export const withProtoProductRecommendations = Component =>
  memo(({ name = "ProtoProductRecommendations", recommendations: rawRecommendations, related: rawRelated }: any) => {
    Component.displayName = name
    const { productNormaliser, useProducts } = useShopify()
    const { global } = useTemplate()
    const recommendations = useProducts({ firstImages: 5, firstVariants: 1, handles: rawRecommendations?.map(({ handle }) => handle) || [] })
    const related = useProducts({
      firstImages: 5,
      firstVariants: 1,
      handles: rawRelated?.map(item => item?.shopify?.handle).filter(handle => handle) || [],
    })
    const items =
      related?.length > 0
        ? related?.map((product: any) => productNormaliser(product))
        : recommendations?.map((product: any) => productNormaliser(product))

    const locales = useMemo(
      () => ({
        ...global,
      }),
      [global]
    )
    return useMemo(() => <Component items={items} locales={locales} />, [items, locales])
  })
