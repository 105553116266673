import React from "react"

import { Icon } from "../../../../Icon/Icon"
import { withMediaItem } from "./withMediaItem"
import { StyledImage } from "../../../../Styled/Image"
import {
  Slide,
  Image,
  StyledVideoWrapper,
  StyledVideo,
  PlayCta,
  PlayIconWrapper,
  Images,
  ControllerWrapper,
  ControllerCta,
  ControllerPanel,
  ControllerButton,
  InputRange,
  InputRangeSlider,
} from "./styledMediaSlides"

export const MediaItem = withMediaItem(
  ({
    thumb,
    image,
    sanityVideo,
    shopifyImage,
    ratio,
    ratioReverse,
    autoPlay,
    playing,
    handleClick,
    locales,
    colour,
    handleEnd,
    played,
    muted,
    handleMute,
    handleProgress,
    handleSeekChange,
    handleSeekMouseDown,
    handleSeekMouseUp,
    progress,
    playerRef,
  }): JSX.Element => (
    <Slide>
      {shopifyImage && <StyledImage alt={shopifyImage?.alt} fluid={{ ...shopifyImage, aspectRatio: ratio }} />}
      {image && <Image background={image?.src} paddingRatio={ratioReverse} />}
      {sanityVideo && !image && !shopifyImage && (
        <StyledVideoWrapper className={`group`} paddingRatio={ratioReverse} allowSwiper={autoPlay}>
          {!thumb ? (
            <StyledVideo
              ref={playerRef}
              url={sanityVideo?.video?.link}
              key={sanityVideo?.video?.link}
              playing={playing || false}
              muted={muted}
              playsinline={true}
              volume={1}
              width={"100%"}
              height={"100%"}
              alt={""}
              onEnded={handleEnd}
              onProgress={handleProgress}
            />
          ) : null}
          <Images played={played}>
            <Image background={sanityVideo.previewImage?.src} paddingRatio={ratio} />
          </Images>

          {thumb ? (
            <ControllerWrapper>
              <ControllerCta display>
                <PlayIconWrapper>
                  <Icon colour={colour} name={`play-cta`} width={40} />
                </PlayIconWrapper>
              </ControllerCta>
            </ControllerWrapper>
          ) : (
            <ControllerWrapper>
              <ControllerCta display={!played}>
                <PlayCta onClick={handleClick} title={locales?.additionalPlay}>
                  <Icon colour={colour} name={`play-cta`} title={locales?.additionalPlay} width={120} />
                </PlayCta>
              </ControllerCta>

              <ControllerPanel display={played}>
                {/** Play button */}
                <ControllerButton onClick={handleClick} title={playing ? locales?.additionalPause : locales?.additionalPlay}>
                  <Icon colour={`white`} name={playing ? `pause` : `play`} />
                </ControllerButton>

                {/** Mute button */}
                <ControllerButton onClick={handleMute} title={muted ? locales?.additionalPause : locales?.additionalPlay}>
                  <Icon colour={`white`} name={muted ? `mute` : `unmute`} />
                </ControllerButton>

                <InputRange>
                  <InputRangeSlider
                    type="range"
                    onChange={handleSeekChange}
                    onTouchMove={handleSeekChange}
                    onMouseDown={handleSeekMouseDown}
                    onMouseUp={handleSeekMouseUp}
                    onTouchStart={handleSeekMouseDown}
                    onTouchEnd={handleSeekMouseUp}
                    min={0}
                    max={0.999999}
                    step="any"
                    value={progress}
                    defaultValue="0"
                  />
                </InputRange>
              </ControllerPanel>
            </ControllerWrapper>
          )}
        </StyledVideoWrapper>
      )}
    </Slide>
  )
)
