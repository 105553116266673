import React from "react"

import { withProtoProductRecommendations } from "./withProtoProductRecommendations"
import { FeaturedCollectionSlides } from "../../../../Sections/FeaturedCollection/Slides/FeaturedCollectionSlides"
import { Container } from "./styledProtoProductRecommendations"

export const ProtoProductRecommendations = withProtoProductRecommendations(
  ({ items, locales }): JSX.Element => (
    <Container>
      <FeaturedCollectionSlides loop={false} items={items} locales={locales} autoplay={false} colour={`black`} prototype={true} />
    </Container>
  )
)
