import React from "react"

import { withProductContentAccordion } from "./withProductContentAccordion"
import { Accordion } from "../../../Sections/Accordion/Accordion"

export const ProductContentAccordion = withProductContentAccordion(
  ({ items, locales, settings }): JSX.Element => (
    <Accordion {...settings} items={items} title={locales?.additionalFaqs} titleShort={locales?.additionalFaqsShort} />
  )
)
