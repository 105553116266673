import React from "react"

import { withProtoProductContent } from "./withProtoProductContent"
import { ProductContentIngredients } from "../../Content/Ingredients/ProductContentIngredients"
import { ProtoProductRecommendations } from "./Recommendations/ProtoProductRecommendations"
import { Recommendations, Faqs } from "./styledProtoProductContent"
import { Accordion } from "../../../Sections/Accordion/Accordion"

export const ProtoProductContent = withProtoProductContent(
  ({ colour, content, ingredients, product, template, recommendations, settings }): JSX.Element => (
    <>
      <ProductContentIngredients
        colour={product?.colour}
        content={content}
        ingredients={ingredients}
        product={product}
        template={template}
        prototype={true}
      />
      {content?.related?.length > 0 && (
        <Recommendations>
          <ProtoProductRecommendations recommendations={recommendations} related={content?.related} />
        </Recommendations>
      )}
      {content?.faqs?.length > 0 && (
        <Faqs>
          <Accordion
            {...settings}
            items={content?.faqs}
            prototype={true}
            titleShort={template?.additionalFaqsShort}
            title={template?.additionalFaqs}
          />
        </Faqs>
      )}
    </>
  )
)
