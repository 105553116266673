import tw, { css, styled } from "twin.macro"

import { StyledBreadcrumbs, StyledBreadcrumbsContainer } from "../../../Styled/Breadcrumb"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { StyledLink } from "../../../Styled/Button"
import { P, Small, Overline, RichText } from "../../../Styled/Text"

export const Breadcrumbs = tw(StyledBreadcrumbs)`my-[0.625rem] md:my-[1.5625rem]`
export const BreadcrumbsContainer = tw(StyledBreadcrumbsContainer)`static lg:top-auto lg:text-grey-darkest lg:w-full p-0 lg:px-0`
export const Column = styled(StyledColumn)`
  ${({ withScroll }) => withScroll && tw`lg:h-full lg:overflow-x-hidden lg:overflow-y-auto py-5.25 md:py-12 lg:py-6.25 lg:px-5 lg:-mx-5`}
  ${({ withScroll }) =>
    withScroll &&
    css`
      &::-webkit-scrollbar {
        ${tw`w-1`}
      }
      &::-webkit-scrollbar-thumb {
        ${tw`bg-grey-light rounded-3xl`}
      }
    `}
`
export const TopContainer = tw.div`lg:flex lg:flex-row justify-center lg:border-b lg:border-grey-light mb-14 lg:mb-32`
export const BottomContainer = tw.div`lg:flex lg:flex-col items-center`
export const ImageContainer = tw.div`lg:w-[55%] lg:max-w-[700px] lg:mr-7 xl:mr-11 lg:pb-[1.675rem]`
export const DetailsContainer = tw.div`lg:w-[45%] lg:min-w-[425px] lg:max-w-[576px] lg:pl-10 xl:pl-14 lg:border-l lg:border-grey-light pt-6 lg:pt-[calc(21px + 2.5rem)] lg:mt-1 pb-[1.675rem]`
export const Pricing = tw(StyledRow)`mb-8 flex-wrap`
export const Price = tw(P)`font-bold text-xl md:font-medium leading-none md:leading-none md:text-4.25xl mr-3`
export const ComparedPrice = tw.span`line-through mr-2`
export const Afterpay = tw(Small)`font-normal whitespace-nowrap`
export const AfterpayIcon = tw.div`w-17.5 md:w-20 ml-2 md:ml-1.5 -mb-0.25 md:-mb-0.5`
export const OuterContainer = tw.div`px-[1.375rem] lg:px-0 lg:mx-8 mb-14 lg:mb-32`
export const Ingredients = tw(StyledLink)`text-sm md:text-xs font-normal md:font-medium`
export const DescriptionRow = tw(StyledRow)`lg:justify-start`
export const Description = tw(Overline)`text-base`
export const DescriptionContent = tw(RichText)`pr-2 lg:pr-0 mb-[2.875rem]`
export const StyledPageLink = styled(StyledLink)`
  ${tw`underline`}
`
