import React, { memo, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useTemplate } from "../../../hooks/useTemplate"

export const withProductBar = Component =>
  memo(({ name = "ProductBar", colour, active, product, handleClick, title, content, clientId, notify }: any) => {
    const { headerBounds } = useApp()
    const { product: locales } = useTemplate()

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          colour={colour}
          active={active}
          bounds={headerBounds}
          handleClick={handleClick}
          locales={locales}
          product={product}
          title={title}
          content={content}
          clientId={clientId}
          notify={notify}
        />
      ),
      [active, headerBounds, handleClick, locales, product, title, content, clientId, notify, colour]
    )
  })
