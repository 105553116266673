import React, { memo, useMemo } from "react"

export const withProductContentAccordion = Component =>
  memo(({ name = "ProductContentAccordion", colour, items, template }: any) => {
    const settings = useMemo(
      () => ({
        background: colour,
        heading: `white`,
      }),
      [colour]
    )

    Component.displayName = name
    return useMemo(() => (items?.length > 0 ? <Component items={items} locales={template} settings={settings} /> : null), [items, template, settings])
  })
