import tw, { styled } from "twin.macro"

import { StyledLink, StyledButton } from "../../../Styled/Button"
import { StyledRow } from "../../../Styled/Row"
import { H6 } from "../../../Styled/Text"

export const Button = styled(StyledButton)`
  ${tw`mb-4`}
  ${({ notify }) => !notify && tw`mb-11.5`}
`
export const Link = tw(StyledLink)`block p-2`
export const Notify = tw(H6)`mb-11.5`
export const Row = tw(StyledRow)`md:mt-2.5 mb-7.5 md:mb-10`
