import React from "react"

import { withProductBar } from "./withProductBar"
import { StyledContainer } from "../../Styled/Container"
import { Bar, Button } from "./styledProductBar"

export const ProductBar = withProductBar(({ colour, active, bounds, handleClick, product, title, content, clientId, notify }) => (
  <Bar active={active} colour={`${product?.colour}-pastel`} top={bounds?.height}>
    <StyledContainer width={`xl`}>
      <Button
        colour={colour}
        bgColour={product?.colour === "white" ? "white" : null}
        onClick={handleClick}
        size={`secondary`}
        title={title}
        data-clientid={clientId ? clientId : null}
        notify={notify}
        active={active}
      >
        {content}
      </Button>
    </StyledContainer>
  </Bar>
))
