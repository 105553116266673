import React, { memo, useMemo } from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useCheckoutContext } from "../hooks/useCheckout"
import { useShopify } from "../hooks/useShopify"

import { Product as Template } from "../components/Product/Product"
import { Error as Page } from "../components/Error/Error"

export const query = graphql`
  query ($handle: String!, $siblingQuery: String!) {
    page: sanityProduct(shopify: { shopifyDeleted: { ne: true }, shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityProductFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      content {
        extraMedia: _rawExtraMedia(resolveReferences: { maxDepth: 6 })
        extraProductContentVideo: _rawExtraProductContentVideo(resolveReferences: { maxDepth: 4 })
        why: _rawWhy(resolveReferences: { maxDepth: 2 })
        ingredients {
          description
          id
          title
        }
        recipe {
          description
          icon
          id
          title
          iconFile
        }
        smells {
          id
          image: _rawImage(resolveReferences: { maxDepth: 2 })
          title
        }
        feels {
          id
          image: _rawImage(resolveReferences: { maxDepth: 2 })
          title
        }
        suitability {
          id
          title
        }
        usage {
          id: _key
          content: _rawContent(resolveReferences: { maxDepth: 2 })
          title
        }
        faqs {
          id: _key
          content: _rawContent(resolveReferences: { maxDepth: 2 })
          title
        }
        primaryCollection: _rawPrimaryCollection(resolveReferences: { maxDepth: 6 })
        related {
          shopify {
            handle: shopifyHandle
          }
        }
        protoAccordionContent {
          id: _key
          content: _rawContent(resolveReferences: { maxDepth: 2 })
          title
        }
        isPrototype
        protoImageText {
          preTitle
          title
          content
          image: _rawImage(resolveReferences: { maxDepth: 6 })
        }
        link: _rawLink(resolveReferences: { maxDepth: 6 })
      }
    }
    grouped: allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } }, tags: { eq: $siblingQuery } }) {
      nodes {
        ...GatsbySanityProductFragment
      }
    }
    template: sanityTemplateProduct {
      banned
      brand: _rawBrand(resolveReferences: { maxDepth: 2 })
      features
      recommendationsArrows
      recommendationsAutoplay
      recommendationsAutoplaySeconds
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalFaqs
      additionalFaqsShort
      additionalIngredients
      additionalBanned
      additionalPause
      additionalPlay
      additionalFeels
      additionalSmells
      additionalSuitability
      additionalRelated
      additionalReviews
      additionalReviewsEmpty
      additionalReviewsName
      additionalReviewsEmail
      additionalReviewsLocation
      additionalReviewsTitle
      additionalReviewsContent
      additionalReviewsSubmit
      additionalReviewsSuccess
      additionalReviewsWrite
      additionalQuantityAdd
      additionalQuantityRemove
      additionalAddToCart
      additionalNotify
      additionalNotifyTitle
      additionalAfterpay
      additionalDescription
      additionalIngredientsButton
      additionalWhy
    }
    error: sanityPageError {
      title
      content
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      link: _rawLink(resolveReferences: { maxDepth: 6 })
    }
  }
`

const Component = memo(({ data, ...props }: any): JSX.Element => {
  const {
    helpers: { encodeShopifyId },
    graphql: {
      queries: { GET_PRODUCT_STANDARD },
    },
  } = useCore()
  const { countryCode } = useCheckoutContext()
  const { useQuery } = useShopify()
  const { page } = data

  const {
    data: live,
    loading,
    error,
  } = useQuery(GET_PRODUCT_STANDARD, {
    fetchPolicy: "cache-and-network",
    variables: {
      countryCode,
      handle: page?.shopify?.handle,
      id: encodeShopifyId(page?.shopify?.id, "Product"),
      parentQuery: `tag:'parent:${page?.shopify?.handle}'`,
      firstCollections: 5,
      firstImages: 0,
      firstMedia: 10,
      metafieldIdentifiers: { namespace: "", key: "" },
      firstVariants: 100,
    },
  })

  if (error) console.error(error)

  return useMemo(
    () =>
      !error && (loading || (!loading && live?.product?.handle)) ? (
        <Template {...props} {...data} live={live} />
      ) : (
        <Page {...props} page={data?.error} />
      ),
    [data, error, live, loading, props]
  )
})

export default Component
