import React, { memo, useMemo } from "react"

import { useDom } from "../../../../../hooks/useDom"
import { useTemplate } from "../../../../../hooks/useTemplate"

export const withProductContentReviewsPagination = Component =>
  memo(({ name = "ProductContentReviewsPagination", active, handleClick, pages: rawPages }: any) => {
    const { dom } = useDom()
    const { global: locales } = useTemplate()

    const pages = useMemo(() => Math.ceil(rawPages / (dom?.isLarge ? 4 : dom?.isMedium ? 2 : 1)), [dom, rawPages])
    const items = useMemo(
      () =>
        Array.from(Array(pages).keys()).map((_, index) => ({
          active: active === index,
          index,
          hide: pages > 5 && index && index !== pages - 1 && active !== index ? true : false,
          value: index + 1,
        })),
      [active, pages]
    )

    Component.displayName = name
    return useMemo(
      () => (items?.length > 0 ? <Component currentPage={active} handleClick={handleClick} items={items} locales={locales} /> : null),
      [active, handleClick, items, locales]
    )
  })
