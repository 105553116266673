import React, { memo, useMemo, useCallback, useState, useEffect, useRef } from "react"
import { useDom } from "../../../../../hooks/useDom"

// TODO: Move up video player related methods/components

export const withMediaItem = Component =>
  memo(
    ({
      name = "MediaItem",
      seeking,
      setSeeking,
      thumb,
      colour,
      item,
      ratio,
      ratioReverse,
      index,
      activeIndex,
      handleInsertPlayedIndex,
      handleRemovePlayedIndex,
    }: any) => {
      const { dom } = useDom()

      const playerRef = useRef(null)

      const [playing, setPlaying] = useState(false)
      const [played, setPlayed] = useState(false)
      const [muted, setMuted] = useState(true)
      const [progress, setProgress] = useState(0)

      const autoPlay = !dom?.isMedium

      const handleEnd = useCallback(() => {
        setPlayed(prevState => !prevState)
        setPlaying(false)
      }, [setPlayed])

      const handleClick = useCallback(() => {
        setPlaying(state => !state)
        !played && setPlayed(true)
      }, [played, setPlaying])

      const handleMute = useCallback(() => {
        setMuted(state => !state)
      }, [])

      const handleProgress = useCallback(
        state => {
          // only update time slider if not currently seeking
          if (!seeking) {
            setProgress(state.played)
          }
        },
        [seeking]
      )

      const handleSeekChange = event => {
        setProgress(parseFloat(event.target.value))
      }

      const handleSeekMouseDown = useCallback(() => {
        setSeeking && setSeeking(true)
      }, [setSeeking])

      const handleSeekMouseUp = useCallback(
        event => {
          setSeeking && setSeeking(false)
          playerRef?.current?.seekTo(parseFloat(event.target.value))
        },
        [setSeeking]
      )

      // Pause video if the slide is no active
      useEffect(() => {
        if (index !== activeIndex) {
          setMuted(true)
          setPlaying(false)
        }
      }, [activeIndex, index])

      useEffect(() => {
        if (!thumb && handleInsertPlayedIndex && handleRemovePlayedIndex) {
          if (played) {
            handleInsertPlayedIndex(index)
          } else {
            handleRemovePlayedIndex(index)
          }
        }
      }, [thumb, index, played, handleInsertPlayedIndex, handleRemovePlayedIndex])

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            thumb={thumb}
            colour={colour}
            ratio={ratio}
            ratioReverse={ratioReverse}
            shopifyImage={item?.shopify}
            image={item?.image}
            sanityVideo={item?.sanityVideo}
            item={item}
            autoPlay={autoPlay}
            handleEnd={handleEnd}
            handleClick={handleClick}
            playing={playing}
            played={played}
            muted={muted}
            playerRef={playerRef}
            handleMute={handleMute}
            handleProgress={handleProgress}
            handleSeekChange={handleSeekChange}
            handleSeekMouseDown={handleSeekMouseDown}
            handleSeekMouseUp={handleSeekMouseUp}
            progress={progress}
          />
        ),
        [
          thumb,
          colour,
          ratio,
          ratioReverse,
          item,
          autoPlay,
          handleEnd,
          handleClick,
          playing,
          played,
          muted,
          handleMute,
          handleProgress,
          handleSeekMouseDown,
          handleSeekMouseUp,
          progress,
        ]
      )
    }
  )
