import React from "react"
import { Link } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"

import { withProductDetail } from "./withProductDetail"
import { Icon } from "../../Icon/Icon"
import { ProductDetailAddToCart } from "./AddToCart/ProductDetailAddToCart"
import { ProductDetailImage } from "./Image/ProductDetailImage"
import { ProductDetailVariants } from "./Variants/ProductDetailVariants"
import { StyledBreadcrumb } from "../../Styled/Breadcrumb"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { H3, P } from "../../Styled/Text"
import {
  Section,
  Breadcrumbs,
  BreadcrumbsContainer,
  Container,
  Details,
  Content,
  Column,
  Pricing,
  Price,
  ComparedPrice,
  Afterpay,
  AfterpayIcon,
  Row,
  DescriptionContent,
  DescriptionRow,
  Description,
  Ingredients,
} from "./styledProductDetail"

export const ProductDetail = withProductDetail(
  ({
    add,
    bar,
    breadcrumb,
    breadcrumbs,
    formattedPrice,
    formattedCompareAtPrice,
    formattedInstallmentPrice,
    grouped,
    handleQuantity,
    handleScroll,
    handleVisibility,
    hasIngredients,
    loading,
    onSale,
    product,
    quantity,
    selectedVariant,
    template,
  }): JSX.Element => (
    <Section>
      <StyledRow items={`stretch`} withWrap>
        <Column colour={`${product?.colour}-pastel`} width={`lg:1/2`} />
        <Column colour={product?.colour} width={`lg:1/2`}>
          <ProductDetailImage product={product} />
        </Column>
      </StyledRow>
      <Content colour={`${product?.colour}-pastel`}>
        <Container width={`xl`}>
          <Row items={`stretch`} withWrap>
            <Column items={`start`} justify={`between`} vertical width={`lg:2/5 xl:7/20`} withScroll>
              <BreadcrumbsContainer>
                <Breadcrumbs>
                  <StyledBreadcrumb as={Link} title={breadcrumb?.title} to={breadcrumb?.url}>
                    {breadcrumb?.title}
                  </StyledBreadcrumb>
                  {breadcrumbs?.map((breadcrumb: any) => (
                    <StyledBreadcrumb
                      key={breadcrumb?.title?.toString()}
                      as={breadcrumb?.url ? Link : null}
                      title={breadcrumb?.title}
                      to={breadcrumb?.url}
                    >
                      {breadcrumb?.title}
                    </StyledBreadcrumb>
                  ))}
                  <StyledBreadcrumb>{product?.title}</StyledBreadcrumb>
                </Breadcrumbs>
              </BreadcrumbsContainer>
              <VisibilitySensor offset={{ top: 150 }} onChange={handleVisibility} partialVisibility>
                <Details ref={add}>
                  <H3 as={`h1`} uppercase withSpacing={`xxs`}>
                    {product?.title}
                  </H3>
                  {product?.type && (
                    <P large withSpacing={`xs`}>
                      {product?.type}
                    </P>
                  )}
                  {selectedVariant?.priceV2 && (
                    <>
                      {product?.giftcard && onSale ? (
                        <Pricing items={`end`}>
                          <StyledColumn width={`no-shrink`}>
                            <Price large>
                              <ComparedPrice>&nbsp;{formattedPrice}&nbsp;</ComparedPrice>
                              {formattedCompareAtPrice}
                            </Price>
                          </StyledColumn>
                          <StyledColumn width={`grow`}>
                            <StyledRow justify={`start`} items={`end`}>
                              <Afterpay>{template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)}</Afterpay>
                              <AfterpayIcon>
                                <Icon name={`afterpay`} title={template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)} />
                              </AfterpayIcon>
                            </StyledRow>
                          </StyledColumn>
                        </Pricing>
                      ) : (
                        <Pricing items={`end`}>
                          <StyledColumn width={`no-shrink`}>
                            <Price large>
                              {+selectedVariant?.compareAtPrice?.amount > +selectedVariant?.price?.amount ||
                              +selectedVariant?.compareAtPriceV2?.amount > +selectedVariant?.priceV2?.amount ? (
                                <ComparedPrice>&nbsp;{formattedCompareAtPrice}&nbsp;</ComparedPrice>
                              ) : null}
                              {formattedPrice}
                            </Price>
                          </StyledColumn>
                          <StyledColumn width={`grow`}>
                            <StyledRow justify={`start`} items={`end`}>
                              <Afterpay>{template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)}</Afterpay>
                              <AfterpayIcon>
                                <Icon name={`afterpay`} title={template?.additionalAfterpay?.replace(`{price}`, formattedInstallmentPrice)} />
                              </AfterpayIcon>
                            </StyledRow>
                          </StyledColumn>
                        </Pricing>
                      )}
                    </>
                  )}
                  <StyledRow withWrap>
                    <StyledColumn width={!product?.giftcard ? `2/3` : `full`}>
                      {!loading && <ProductDetailVariants grouped={grouped} product={product} />}
                    </StyledColumn>
                    {!product?.giftcard && (
                      <StyledColumn width={`1/3`}>
                        <ProductDetailAddToCart
                          colour={product?.colour}
                          component={`quantity`}
                          handleChange={handleQuantity}
                          quantity={quantity}
                          template={template}
                          bar={bar}
                        />
                      </StyledColumn>
                    )}
                    <StyledColumn>
                      <ProductDetailAddToCart
                        colour={product?.colour}
                        component={`button`}
                        loading={loading}
                        product={product}
                        quantity={quantity}
                        template={template}
                        bar={bar}
                      />
                    </StyledColumn>
                  </StyledRow>
                  <DescriptionRow gutter={`sm`} items={`center`} withSpacing={`xs`}>
                    <StyledColumn gutter={`sm`} width={`no-shrink`}>
                      <Description colour={product?.colour} large>
                        {template?.additionalDescription}
                      </Description>
                    </StyledColumn>
                    {hasIngredients && (
                      <StyledColumn gutter={`sm`} width={`no-shrink`}>
                        <Ingredients colour={product?.colour} onClick={handleScroll} title={template?.additionalIngredientsButton}>
                          {template?.additionalIngredientsButton}
                        </Ingredients>
                      </StyledColumn>
                    )}
                  </DescriptionRow>
                  <DescriptionContent colour={`grey-darker`}>{product?.description}</DescriptionContent>
                </Details>
              </VisibilitySensor>
              <div />
            </Column>
          </Row>
        </Container>
      </Content>
    </Section>
  )
)
