import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Mousewheel, Pagination, Navigation, Thumbs } from "swiper"

import { withMediaSlides } from "./withMediaSlides"
import { StyledRow } from "../../../../Styled/Row"
import { Icon } from "../../../../Icon/Icon"
import { MediaItem } from "./MediaItem"
import { MeatballWrapper, Meatballs, Wrapper, SlideWrapper, ThumbnailWrapper, ThumbnailInner, Arrow, Wishlist } from "./styledMediaSlides"

SwiperCore.use([Autoplay, Mousewheel, Pagination, Navigation, Thumbs])

export const MediaSlides = withMediaSlides(
  ({
    id,
    items,
    ratio,
    ratioReverse,
    colour,
    locales,
    activeIndex,
    handleSlideChange,
    setMainSwiper,
    thumbnailSwiper,
    setThumbnailSwiper,
    thumbnailRef,
    thumbnailHeight,
    wishlist,
    handleWishlist,
    seeking,
    setSeeking,
    meatballVisibility,
    handleInsertPlayedIndex,
    handleRemovePlayedIndex,
    prototype,
  }): JSX.Element => (
    <Wrapper>
      <SlideWrapper>
        <Swiper
          thumbs={{ swiper: thumbnailSwiper }}
          loop
          allowTouchMove={true}
          focusableElements={"input"}
          autoplay={false}
          slidesPerView={1}
          spaceBetween={0}
          threshold={1}
          pagination={{ clickable: false, el: `#${id}-pagination` }}
          onSlideChangeTransitionStart={props => {
            handleSlideChange(props.realIndex)
          }}
          onSwiper={setMainSwiper}
          style={{
            width: "100%",
          }}
        >
          {items?.map((item, index) => (
            <SwiperSlide key={`${index}_${item?.id}`}>
              <MediaItem
                colour={colour}
                item={item}
                ratio={ratio}
                ratioReverse={ratioReverse}
                index={index}
                activeIndex={activeIndex}
                seeking={seeking}
                setSeeking={setSeeking}
                handleInsertPlayedIndex={handleInsertPlayedIndex}
                handleRemovePlayedIndex={handleRemovePlayedIndex}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Wishlist
          active={wishlist}
          colour={`white`}
          onClick={event => handleWishlist(event)}
          title={wishlist ? locales?.additionalRemoveFromWishlist : locales?.additionalAddToWishlist}
          prototype={prototype}
        >
          <Icon fill={wishlist ? `auto` : `none`} name={wishlist ? `heart` : `heart-outline`} />
        </Wishlist>

        <MeatballWrapper width={`xl`} display={meatballVisibility}>
          <StyledRow justify={`center`}>
            <Meatballs colour={`grey-dark`} id={`${id}-pagination`} />
          </StyledRow>
        </MeatballWrapper>
      </SlideWrapper>

      <ThumbnailWrapper>
        <ThumbnailInner ref={thumbnailRef}>
          {thumbnailHeight || thumbnailSwiper ? (
            <Swiper
              watchSlidesProgress={true}
              loop
              direction={"vertical"}
              onSwiper={setThumbnailSwiper}
              navigation={{ prevEl: `#${id}-up`, nextEl: `#${id}-down` }}
              spaceBetween={6}
              slidesPerView={4}
              threshold={1}
              freeMode={true}
              style={{
                width: "100%",
                height: `${thumbnailHeight}px`,
              }}
            >
              {items?.map((item, index) => (
                <SwiperSlide key={`${index}_thumbnail_${item?.id}`}>
                  <MediaItem thumb colour={colour} item={item} ratio={ratio} ratioReverse={ratioReverse} index={index} activeIndex={activeIndex} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </ThumbnailInner>
        <Arrow colour={colour} id={`${id}-up`} position={`up`} title={locales?.additionalPrevious}>
          <Icon name={`up`} />
        </Arrow>
        <Arrow colour={colour} id={`${id}-down`} position={`down`} title={locales?.additionalNext}>
          <Icon name={`down-arrow`} />
        </Arrow>
      </ThumbnailWrapper>
    </Wrapper>
  )
)
