import React, { Fragment } from "react"
import { Link as GatsbyLink } from "gatsby"

import { withProductDetailVariants } from "./withProductDetailVariants"
import { StyledPill } from "../../../Styled/Pill"
import { Pills } from "./styledProductDetailVariants"

export const ProductDetailVariants = withProductDetailVariants(
  ({ handleVariant, options, product, selectedOptions, prototype = false }): JSX.Element => (
    <>
      <Pills grid>
        {options?.map(({ name, values }) => (
          <Fragment key={name?.toString()}>
            {values?.map(({ id, title, type, url }) =>
              type === `grouped` ? (
                <StyledPill key={id} as={GatsbyLink} colour={product?.colour} grid to={url} title={title} prototype={prototype}>
                  {title}
                </StyledPill>
              ) : (
                <StyledPill
                  key={id}
                  active={selectedOptions?.find((selectedOption: any) => selectedOption?.name === name)?.value === title}
                  colour={product?.colour}
                  grid
                  onClick={() => handleVariant({ name, value: title })}
                  title={title}
                  prototype={prototype}
                >
                  {title}
                </StyledPill>
              )
            )}
          </Fragment>
        ))}
      </Pills>
    </>
  )
)
