import React from "react"

import { MediaSlides } from "./Slides/MediaSlides"
import { withProductDetailImage } from "./withProductDetailImage"
import { Icon } from "../../../Icon/Icon"
import { StyledImage } from "../../../Styled/Image"
import { AspectRatio, Images, Wishlist } from "./styledProductDetailImage"

export const ProductDetailImage = withProductDetailImage(
  ({ colour, handleWishlist, image, mediaGroup, locales, ratio, ratioReverse, title, wishlist, showNavigation, prototype }) => (
    <Images>
      {mediaGroup ? (
        <MediaSlides
          id={"product-image-details"}
          items={mediaGroup}
          ratio={ratio}
          locales={locales}
          ratioReverse={ratioReverse}
          showNavigation={showNavigation}
          colour={colour}
          wishlist={wishlist}
          handleWishlist={handleWishlist}
        />
      ) : image ? (
        <StyledImage alt={image?.alt || title} fluid={{ ...image, aspectRatio: ratio }} />
      ) : (
        <AspectRatio />
      )}
      {!mediaGroup ? (
        <Wishlist
          active={wishlist}
          colour={`white`}
          onClick={event => handleWishlist(event)}
          title={wishlist ? locales?.additionalRemoveFromWishlist : locales?.additionalAddToWishlist}
          prototype={prototype}
        >
          <Icon fill={wishlist ? `auto` : `none`} name={wishlist ? `heart` : `heart-outline`} />
        </Wishlist>
      ) : null}
    </Images>
  )
)
