import React, { memo, useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { useDom } from "../../hooks/useDom"
import { useImage } from "../../hooks/useImage"
import { useRoutes } from "../../hooks/useRoutes"

export const withError = Component =>
  memo(({ name = "Error", page }: any) => {
    const {
      helpers: { isBrowser },
    } = useCore()
    const { dom } = useDom()
    const { getResponsiveImage } = useImage()
    const { linkResolver } = useRoutes()

    const images = getResponsiveImage(page?.image, { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 1000 } })
    const ratios = {
      desktop: 2 / 1,
      mobile: 125 / 152,
    }
    const image = dom?.isMedium ? images?.desktop : images?.mobile
    const ratio = dom?.isMedium ? ratios?.desktop : ratios?.mobile
    const link = linkResolver(page?.link)

    Component.displayName = name
    return useMemo(
      () => (isBrowser ? <Component content={page?.content} image={image} link={link} page={page} ratio={ratio} /> : null),
      [image, isBrowser, link, page, ratio]
    )
  })
