import React, { memo, useMemo } from "react"

import { useShopifyVariants, useShopify } from "../../../../hooks/useShopify"

export const withProductDetailVariants = Component =>
  memo(({ name = "ProductDetailVariants", grouped: rawGrouped, product, prototype }: any) => {
    const { productNormaliser } = useShopify()
    const { selectedOptions, handleVariant } = useShopifyVariants({
      product,
      useParameter: true,
    })

    const grouped = rawGrouped?.nodes?.map((product: any) =>
      productNormaliser({ ...product, ...(product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : {}) })
    )
    const items = grouped?.reduce(
      (items, item) => [
        ...items,
        ...(item?.variants?.map(({ id, title }) => ({
          id: `${item?.handle}-${id}`,
          title,
          type: `grouped`,
          url: item?.url,
        })) || []),
      ],
      []
    )
    const options = useMemo(
      () => [
        {
          ...(product?.options?.[0] || {}),
          values: [
            ...(product?.options?.[0]?.values?.map(value => ({
              id: `${product?.handle}-${value}`,
              type: `value`,
              title: value,
            })) || []),
            ...items,
          ]?.sort((a, b) => a.title?.replace(`ml`, ``) - b.title?.replace(`ml`, ``)),
        },
        ...(product?.options?.[1] || []),
        ...(product?.options?.[2] || []),
      ],
      [items, product]
    )

    Component.displayName = name
    return useMemo(
      () =>
        product?.options?.length > 0 && selectedOptions?.length > 0 ? (
          <Component handleVariant={handleVariant} options={options} product={product} selectedOptions={selectedOptions} prototype={prototype} />
        ) : null,
      [handleVariant, options, product, selectedOptions]
    )
  })
