import React, { memo, useMemo, useCallback, useState, useEffect } from "react"

import { useBreadcrumb } from "../../../../hooks/useBreadcrumb"
import { useRoutes } from "../../../../hooks/useRoutes"
import { useShopifyPrice } from "../../../../hooks/useShopify"
import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"
import { useDom } from "../../../../hooks/useDom"

export const withProtoProductDetail = Component =>
  memo(({ name = "ProtoProductDetail", product, bar, setBar, template, loading, grouped, ingredients, add, recommendations }: any) => {
    const { breadcrumb } = useBreadcrumb()
    const { activeVariant } = useApp()
    const {
      helpers: { isBrowser },
    } = useCore()
    const { dom } = useDom()
    const { linkResolver, parentCollections } = useRoutes()
    const { formattedPrice, formattedCompareAtPrice, formattedInstallmentPrice, onSale } = useShopifyPrice(activeVariant, product.giftcard)
    const [quantity, setQuantity] = useState(1)
    const [items, setItems] = useState([])
    const link = linkResolver(product?.content?.link)
    const handleQuantity = useCallback((quantity: number) => setQuantity(quantity), [])
    const hasIngredients = product?.content?.recipe?.length > 0 || product?.content?.ingredients?.length > 0
    const breadcrumbs = useMemo(
      () => [
        ...(product?.metadata?.breadcrumbs?.map((breadcrumb: any) => linkResolver(breadcrumb)) || []),
        ...parentCollections(product?.content?.primaryCollection, true),
      ],
      [linkResolver, parentCollections, product]
    )
    const handleVisibility = useCallback(
      (visible: boolean) => {
        if (bar === visible) {
          setBar(!visible)
        }
      },
      [bar, setBar]
    )

    const handleScroll = useCallback(
      () =>
        isBrowser && ingredients?.current
          ? window.scrollTo({ top: ingredients.current?.offsetTop + (dom?.isLarge ? -40 : dom?.width), behavior: "smooth" })
          : null,
      [dom, ingredients, isBrowser]
    )

    useEffect(() => {
      setItems(product?.content?.protoAccordionContent || [])
    }, [product, items, setItems])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          product={product}
          breadcrumb={breadcrumb}
          breadcrumbs={breadcrumbs}
          handleVisibility={handleVisibility}
          selectedVariant={activeVariant}
          formattedPrice={formattedPrice}
          formattedCompareAtPrice={formattedCompareAtPrice}
          formattedInstallmentPrice={formattedInstallmentPrice}
          onSale={onSale}
          template={template}
          loading={loading}
          grouped={grouped}
          bar={bar}
          quantity={quantity}
          handleQuantity={handleQuantity}
          handleScroll={handleScroll}
          ingredients={ingredients}
          hasIngredients={hasIngredients}
          add={add}
          items={items}
          recommendations={recommendations}
          link={link}
        />
      ),
      [
        product,
        breadcrumb,
        breadcrumbs,
        handleVisibility,
        activeVariant,
        formattedPrice,
        formattedCompareAtPrice,
        formattedInstallmentPrice,
        onSale,
        template,
        loading,
        grouped,
        bar,
        quantity,
        handleQuantity,
        ingredients,
        add,
        items,
        handleScroll,
        hasIngredients,
        recommendations,
        link,
      ]
    )
  })
