import React, { memo, useMemo } from "react"

export const withProtoProductContent = Component =>
  memo(({ name = "ProtoProductContent", ingredients, product, template, recommendations }: any) => {
    Component.displayName = name
    const { colour, content } = product
    const settings = useMemo(
      () => ({
        background: colour,
        heading: `black`,
      }),
      [colour]
    )

    return useMemo(
      () => (
        <Component
          colour={colour}
          content={content}
          ingredients={ingredients}
          product={product}
          template={template}
          recommendations={recommendations}
          settings={settings}
        />
      ),
      [colour, content, ingredients, product, template, recommendations, settings]
    )
  })
