import React, { memo, useMemo } from "react"

export const withProductContentIcons = Component =>
  memo(({ name = "ProductContentIcons", colour, id, items }: any) => {
    const settings = useMemo(
      () => ({
        text: colour,
      }),
      [colour]
    )

    Component.displayName = name
    return useMemo(() => (items?.length > 0 ? <Component id={id} items={items} settings={settings} /> : null), [id, items, settings])
  })
