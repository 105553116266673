import tw, { styled } from "twin.macro"

import { StyledLink } from "../../../../Styled/Button"

export const Images = tw.div`relative w-full z-1`
export const AspectRatio = tw.div`w-full pb-75/83 md:pb-9/10`
export const Wishlist = styled(StyledLink)`
  ${tw`absolute top-14 right-6.25 md:top-8 md:right-8 z-10`}

  svg {
    ${tw`w-6 md:w-8`}
  }
  ${({ prototype }) => prototype && tw`top-6.25`}
`
