import React, { memo, useCallback, useMemo, useState, useRef } from "react"

import { useCore } from "../../../../hooks/useCore"
import { useDom } from "../../../../hooks/useDom"
import { useImage } from "../../../../hooks/useImage"

export const withProductContentVideo = Component =>
  memo(({ name = "ProductContentVideo", colour, content: rawContent, product, template }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { dom } = useDom()
    const playerRef = useRef(null)
    const { getFluidImage, getResponsiveImage } = useImage()
    const [playing, setPlaying] = useState(false)
    const [played, setPlayed] = useState(false)
    const [muted, setMuted] = useState(true)
    const [progress, setProgress] = useState(0)
    const [seeking, setSeeking] = useState(false)

    const video = product?.videos?.[0]
    const ratio = dom?.isMedium ? 9 / 10 : 75 / 83
    const ratioReverse = dom?.isMedium ? 10 / 9 : 83 / 75
    const image = product?.images?.[1] || product?.images?.[0]
    const content = useMemo(
      () => ({
        ...rawContent,
        feels: rawContent?.feels?.map((item: any) => ({
          ...item,
          image: getFluidImage(item?.image, { maxWidth: 800 }),
        })),
        smells: rawContent?.smells?.map((item: any) => ({
          ...item,
          image: getFluidImage(item?.image, { maxWidth: 800 }),
        })),
        why: sanityContent(rawContent?.why),
      }),
      [getFluidImage, rawContent, sanityContent]
    )
    const sanityVideo = useMemo(() => {
      if (!content?.extraProductContentVideo?.video?.enabled) {
        return null
      }

      const responsivePreviewImage = getResponsiveImage(content?.extraProductContentVideo?.previewImage, {
        desktop: { maxWidth: 1000 },
        mobile: { maxWidth: 800 },
      })

      return {
        previewImage: dom?.isMedium ? responsivePreviewImage?.desktop : responsivePreviewImage?.mobile,
        video: dom?.isMedium ? content?.extraProductContentVideo?.video?.desktop : content?.extraProductContentVideo?.video?.mobile,
      }
    }, [
      content?.extraProductContentVideo?.previewImage,
      content?.extraProductContentVideo?.video?.desktop,
      content?.extraProductContentVideo?.video?.enabled,
      content?.extraProductContentVideo?.video?.mobile,
      dom?.isMedium,
      getResponsiveImage,
    ])

    const handleEnd = useCallback(() => {
      setPlayed(prevState => !prevState)
      setPlaying(false)
    }, [setPlayed])

    const handleClick = useCallback(() => {
      setPlaying(state => !state)
      !played && setPlayed(true)
    }, [played, setPlaying])

    const handleMute = useCallback(() => {
      setMuted(state => !state)
    }, [])

    const handleProgress = useCallback(
      state => {
        // only update time slider if not currently seeking
        if (!seeking) {
          setProgress(state.played)
        }
      },
      [seeking]
    )

    const handleSeekChange = event => {
      setProgress(parseFloat(event.target.value))
    }

    const handleSeekMouseDown = () => {
      setSeeking(true)
    }

    const handleSeekMouseUp = event => {
      setSeeking(false)
      playerRef?.current?.seekTo(parseFloat(event.target.value))
    }

    Component.displayName = name
    return useMemo(
      () =>
        video || content?.feels?.length > 0 || content?.smells?.length > 0 || content?.why ? (
          <Component
            colour={colour}
            content={content}
            handleEnd={handleEnd}
            handleClick={handleClick}
            image={image}
            locales={template}
            played={played}
            playing={playing}
            ratio={ratio}
            ratioReverse={ratioReverse}
            title={product?.title}
            video={video}
            sanityVideo={sanityVideo}
            muted={muted}
            handleMute={handleMute}
            playerRef={playerRef}
            handleProgress={handleProgress}
            handleSeekChange={handleSeekChange}
            handleSeekMouseDown={handleSeekMouseDown}
            handleSeekMouseUp={handleSeekMouseUp}
            progress={progress}
          />
        ) : null,
      [
        video,
        content,
        colour,
        handleEnd,
        handleClick,
        image,
        template,
        played,
        playing,
        ratio,
        ratioReverse,
        product?.title,
        sanityVideo,
        muted,
        handleMute,
        handleProgress,
        progress,
      ]
    )
  })
