import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useApp } from "../../../../../hooks/useApp"
import { useFunctions } from "../../../../../hooks/useFunctions"
import { useTemplate } from "../../../../../hooks/useTemplate"

const INITIAL_STATE = { author: "", email: "", location: "", reviewMessage: "", reviewRating: 0, reviewTitle: "" }

export const withProductContentReviewsForm = Component =>
  memo(({ name = "ProductContentReviewsForm", active, handleActive, product: item, template }: any) => {
    const { activeVariant, headerBounds } = useApp()
    const { callFunction, errors, loading } = useFunctions()
    const { global } = useTemplate()
    const [data, setData] = useState(INITIAL_STATE)
    const [success, setSuccess] = useState(false)
    const field = useRef()

    const locales = useMemo(
      () => ({
        ...global,
        ...template,
      }),
      [global, template]
    )

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()

        const options = {
          ...data,
          productId: item?.legacyId,
          productSKU: activeVariant?.sku,
        }

        const response = await callFunction("product-review", options)

        if (response?.body?.id) {
          setData(INITIAL_STATE)
          setSuccess(true)
        }
      },
      [data, item, callFunction, activeVariant]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) =>
        setData((prevState: any) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        })),
      []
    )

    useEffect(() => {
      active && field?.current ? setTimeout(() => field.current.select(), 200) : null
    }, [active, field])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          active={active}
          bounds={headerBounds}
          data={data}
          errors={errors}
          field={field}
          handleActive={handleActive}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          locales={locales}
          success={success}
        />
      ),
      [active, headerBounds, data, errors, field, handleActive, handleChange, handleSubmit, loading, locales, success]
    )
  })
