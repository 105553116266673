import React, { memo, useCallback, useMemo, useState } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

export const withProductContentTicker = Component =>
  memo(({ name = "ProductContentTicker", colour, items }: any) => {
    const [position, setPosition] = useState(false)
    const [visible, setVisible] = useState(false)

    useScrollPosition(({ currPos }) => setPosition(currPos?.y), [], null, true, 10)

    const handleVisibility = useCallback((visible: boolean) => setVisible(visible), [setVisible])

    Component.displayName = name
    return useMemo(
      () =>
        items?.length > 0 ? (
          <Component colour={colour} handleVisibility={handleVisibility} items={items} position={position} visible={visible} />
        ) : null,
      [colour, handleVisibility, items, position, visible]
    )
  })
