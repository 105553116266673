import React from "react"
import { Link } from "gatsby"

import { withError } from "./withError"
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"
import { StyledButton } from "../Styled/Button"
import { StyledContainer } from "../Styled/Container"
import { StyledColumn } from "../Styled/Column"
import { StyledRow } from "../Styled/Row"
import { H4 } from "../Styled/Text"
import { Content, Image, Page, Title } from "./styledError"

export const Error = withError(
  ({ content, image, link, page, ratio }): JSX.Element => (
    <Page>
      <Image alt={image?.alt} fluid={{ ...image, aspectRatio: ratio }} />
      <Breadcrumbs page={page} />
      <Content>
        <StyledContainer width={`sm`}>
          <StyledRow items={`center`}>
            <StyledColumn vertical items={`center`}>
              <Title>{page?.title}</Title>
              <H4 align={`center`} colour={`white`} withSpacing>
                {content}
              </H4>

              {link && (
                <StyledButton as={Link} size={`primary`} colour={`purple`} title={link?.title} to={link?.url}>
                  {link?.title}
                </StyledButton>
              )}
            </StyledColumn>
          </StyledRow>
        </StyledContainer>
      </Content>
    </Page>
  )
)
