import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"
import { StyledRow } from "../../../Styled/Row"
import { P } from "../../../Styled/Text"

export const Section = tw.section`pt-12 pb-8 md:pt-20 md:pb-11.75 overflow-hidden`
export const Button = tw(StyledButton)`py-1 md:py-2.75 md:w-75`
export const Header = tw(StyledRow)`mb-8 md:mb-16`
export const Slides = styled.div`
  .swiper-slide {
    ${tw`h-auto`}
  }
`
export const Review = tw.figure`h-full flex flex-col items-start justify-between pb-17.5`
export const Top = tw.div`flex-grow md:max-h-64 md:overflow-x-hidden md:overflow-y-auto`
export const Bottom = tw.figcaption`flex-shrink-0 mt-8`
export const Author = tw(P)`font-semibold`
export const Star = tw.div`pr-0.5 last:pr-0`
export const Reply = tw(P)`italic mt-6 pl-2 md:max-h-48 md:overflow-x-hidden md:overflow-y-auto`
