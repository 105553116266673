import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledLink } from "../../../Styled/Button"
import { StyledContainer } from "../../../Styled/Container"
import { StyledColumn } from "../../../Styled/Column"
import { StyledImage } from "../../../Styled/Image"
import { StyledRow } from "../../../Styled/Row"
import { Overline, P, RichText } from "../../../Styled/Text"

export const Section = styled.section`
  ${tw`relative`}
  ${({ prototype }) => prototype && tw`w-full max-w-[1276px]`}
`
export const AspectRatio = tw.div`w-full pb-75/83 lg:pb-9/10`
export const Content = styled.div`
  ${tw`lg:absolute lg:inset-0 lg:flex lg:items-center lg:justify-between`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
`
export const Row = tw(StyledRow)`relative h-full`
export const Recipe = styled(StyledRow)`
  ${tw`md:last:mb-0 md:w-129.5 max-w-full`}
  ${({ last }) => (last ? tw`mb-11.75 md:mb-18.75` : tw`mb-6 md:mb-8`)}
`
export const Container = tw(StyledContainer)`h-full`
export const Image = tw(StyledImage)`z-1`
export const Title = tw(
  Overline
)`text-left flex items-center justify-start text-xl tracking-normal md:tracking-widest focus:outline-none md:w-129.5 max-w-full`
export const Banned = styled(RichText)`
  ${tw`mt-4 md:w-129.5 max-w-full`}
`
export const BannedIcon = tw.div`ml-2 flex-shrink-0`
export const Ingredients = tw(RichText)`relative mb-10 last:mb-0 md:mb-11 md:last:mb-0 md:w-129.5 max-w-full`
export const Ingredient = styled.button`
  ${tw`focus:outline-none`}
  ${({ inline }) => (inline ? tw`inline mr-1` : tw`relative w-full text-left`)}
`
export const IngredientText = styled(P)`
  ${({ large }) => (large ? tw`md:text-4.25xl font-bold` : tw`inline`)}
  ${({ underline }) => underline && tw`underline cursor-pointer`}
`
export const IconPlaceholder = tw.div`w-10 h-10`
export const IngredientTooltip = tw.div`absolute inline right-0 top-4 z-1 w-55 md:w-92.5 md:max-w-full bg-white text-left rounded-lg shadow py-4 pl-6 pr-10 cursor-default md:pointer-events-none`
export const Close = tw(StyledLink)`block md:hidden absolute right-4 top-4`
export const Column = styled(StyledColumn)`
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ withIndex }) => withIndex && tw`z-1`}
  ${({ withScroll }) => withScroll && tw`lg:h-full lg:overflow-x-hidden lg:overflow-y-auto py-10.5 md:py-14.5`}
  ${({ withScroll }) =>
    withScroll &&
    css`
      &::-webkit-scrollbar {
        ${tw`w-1`}
      }
      &::-webkit-scrollbar-thumb {
        ${tw`bg-grey-light rounded-3xl`}
      }
    `}
`
export const IngredientSvg = styled.svg`
  ${tw`h-10 w-10`}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
`
