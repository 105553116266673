import React, { memo, useEffect, useMemo, useRef, useState } from "react"

import { useShopify } from "../../hooks/useShopify"

export const withProduct = Component =>
  memo(({ name = "Product", grouped, live: rawLive, page, template }: any) => {
    const { productNormaliser } = useShopify()
    const add = useRef()
    const ingredients = useRef()
    const [bar, setBar] = useState(false)
    const [live, setLive] = useState(null)
    const [isPrototype, setIsPrototype] = useState(false)

    const product = useMemo(() => productNormaliser({ ...page, ...(live?.product || {}) }), [live, page, productNormaliser])

    useEffect(() => {
      if (!live && rawLive?.product) setLive(rawLive)
    }, [live, rawLive, setLive])

    useEffect(() => {
      if (!isPrototype && product) {
        setIsPrototype(product?.content?.isPrototype)
      }
    }, [isPrototype, product])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          add={add}
          bar={bar}
          grouped={grouped}
          ingredients={ingredients}
          loading={live === null}
          product={product}
          recommendations={live?.recommendations}
          setBar={setBar}
          template={template}
          isPrototype={isPrototype}
        />
      ),
      [add, bar, grouped, ingredients, live, product, setBar, template, isPrototype]
    )
  })
