import React, { Fragment } from "react"

import { withProductContentReviewsPagination } from "./withProductContentReviewsPagination"
import { Icon } from "../../../../Icon/Icon"
import { Navigation, Numbers, Pages, Items, Item, Arrow, Link } from "./styledProductContentReviewsPagination"

export const ProductContentReviewsPagination = withProductContentReviewsPagination(
  ({ currentPage, handleClick, items, locales }): JSX.Element => (
    <Navigation>
      <Items>
        <Arrow>
          <Link disabled={!currentPage} onClick={() => handleClick(currentPage)} size={`large`} title={locales?.additionalPrevious}>
            <Icon name={`left`} />
          </Link>
        </Arrow>
        <Numbers>
          <Pages>
            {items?.map(({ active, hide, index, value }) => (
              <Fragment key={index?.toString()}>
                {index === items?.length - 1 &&
                  items?.length > 5 &&
                  currentPage !== 0 &&
                  currentPage !== items?.length - 1 &&
                  currentPage !== items?.length - 2 && <Item>...</Item>}
                {!hide && (
                  <Item>
                    <Link active={active} colour={active ? `orange-dark` : `grey`} onClick={() => handleClick(value)} size={`large`} title={value}>
                      {value}
                    </Link>
                  </Item>
                )}
                {!index && items?.length > 5 && currentPage !== 1 && <Item>...</Item>}
              </Fragment>
            ))}
          </Pages>
        </Numbers>
        <Arrow>
          <Link
            disabled={currentPage === items?.length - 1}
            onClick={() => handleClick(currentPage + 2)}
            size={`large`}
            title={locales?.additionalNext}
          >
            <Icon name={`right`} />
          </Link>
        </Arrow>
      </Items>
    </Navigation>
  )
)
