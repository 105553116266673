import tw, { styled } from "twin.macro"

import { GlobalStyles } from "./Global"

const styles = {
  default: tw`flex items-stretch`,
  align: {
    left: tw`justify-start`,
    center: tw`justify-center`,
    right: tw`justify-end`,
  },
  item: tw`block font-sans font-medium text-sm md:text-base leading-none px-1.25 py-2.25 md:p-3 border-r border-grey-light mr-0.25 last:mr-0 text-center min-w-25 bg-white bg-opacity-50 rounded-none first:rounded-l-6xl last:rounded-r-6xl cursor-pointer hover:bg-opacity-75 focus:outline-none transition-colors duration-200`,
  grid: tw`rounded-6xl border-r-0 mr-2 mb-2`,
  active: tw`bg-opacity-100 hover:bg-opacity-100 font-bold md:font-medium`,
  prototype: {
    active: tw`border-black border-[1px] text-black`,
    inactive: tw`border-grey-light border-[1px] text-black hover:border-black`,
  },
}

export const StyledPills = styled.nav`
  ${styles.default}
  ${({ align }) => align && styles.align[align]}
  ${({ grid }) => grid && tw`flex-wrap`}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const StyledPill = styled.button`
  ${styles.item}
  ${({ grid }) => grid && styles.grid}
  ${({ active, colour }) => active && colour && GlobalStyles.colour[colour]}
  ${({ active }) => active && styles.active}
  ${({ active, prototype }) => (active && prototype ? styles.prototype.active : !active && prototype ? styles.prototype.inactive : null)}
`
