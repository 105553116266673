import tw, { css, styled } from "twin.macro"

import { StyledButton, StyledLink } from "../../../../Styled/Button"
import { StyledForm, StyledLabel } from "../../../../Styled/Form"
import { StyledRow } from "../../../../Styled/Row"

export const Review = styled.div`
  ${tw`fixed inset-0 flex items-center justify-center z-20 invisible opacity-0 bg-white bg-opacity-70 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100`}
  ${({ top }) =>
    top &&
    css`
      @media (min-width: 768px) {
        top: ${top}px;
      }
    `}
`
export const Popup = styled.div`
  ${tw`relative bg-orange text-white rounded-lg shadow px-12 pb-12 pt-16 overflow-x-hidden overflow-y-auto w-full max-h-full invisible transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible scale-100 translate-y-0`}
`
export const Inner = tw.div`flex items-center justify-center w-full h-full p-6 max-w-screen-md`
export const Form = tw(StyledForm)`flex flex-col items-center`
export const Close = tw(StyledLink)`absolute top-6 right-6 w-4 md:w-5`
export const Button = tw(StyledButton)`md:w-47.5`
export const Rating = tw(StyledRow)`self-start`
export const Star = styled.div`
  ${tw`relative pr-0.5 last:pr-0`}
  ${({ active }) => (active ? tw`text-purple-dark` : tw`text-grey-light hover:text-purple-light`)}
`
export const Radio = tw.input`absolute block inset-0 w-full h-full opacity-0 cursor-pointer`
export const Label = tw(StyledLabel)`text-white self-start mb-2`
